import { defer, json } from "react-router";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

export function loader({ request, params }) {

    return defer({
      supplierData: getSupplier(params.storeId, params.supplierId),
    });
  }
  
async function getSupplier(storeId, supplierId) {
    const token = getAuthToken();
  
    const response = await fetch(
      Config.apiServerAddress +
        `/suppliers?storeId=${storeId}&supplierId=${supplierId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    if (!response.ok) {
      return {
        message: "Няма отговор от сървъра",
        status: 500
      };
    } else {
      const supplier = await response.json();
      return supplier;
    }
  }

  export { getSupplier };