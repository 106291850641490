import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  useProSidebar,
} from "react-pro-sidebar";
import { Link, NavLink } from "react-router-dom";

import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import ContactsOutlinedIcon from "@mui/icons-material/ContactsOutlined";
import HelpOutlineOutlinedIcon from "@mui/icons-material/HelpOutlineOutlined";
import StorefrontIcon from "@mui/icons-material/Storefront";
import MailIcon from "@mui/icons-material/Mail";
import ChecklistIcon from "@mui/icons-material/Checklist";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import QrCode2Icon from "@mui/icons-material/QrCode2";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LogoutIcon from "@mui/icons-material/Logout";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import TuneIcon from "@mui/icons-material/Tune";
import ManageSearchIcon from "@mui/icons-material/ManageSearch";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";

import "./style/SideNavigation.css";
import style from "./style/Sidebar.module.css";
import { Typography, useMediaQuery } from "@mui/material";
import { AccountBox, LocalShipping } from "@mui/icons-material";
import { checkForLoadedPermission, getUser } from "utils/auth";
import { useEffect, useState } from "react";
import PermissionName from "utils/enums/PermissionName";
import { NewBadge } from "utils/Badges";

export default function SideNavigation(props) {
  const { collapseSidebar } = useProSidebar();
  const user = getUser();
  let selectedBusiness = JSON.parse(localStorage.getItem("selectedBusiness"));
  const [key, setKey] = useState(0);
  const matches = useMediaQuery("(min-width:800px)");

  useEffect(() => {
    if (!matches) {
      collapseSidebar(true);
    } else {
      collapseSidebar(false);
    }
  }, []);

  const hideSidebarHandler = () => {
    if (!matches) {
      console.log("Hide sidebar");
      collapseSidebar(true);
    }
  };

  return (
    <div>
      <Sidebar
        className={style.sideBar}
        transitionDuration={150}
        collapsedWidth={matches ? "80px" : "0px"}
        onClick={hideSidebarHandler}

        // defaultCollapsed={matches ? true : false}
        // defaultCollapsed={false}
      >
        <Menu>
          {selectedBusiness && (
            <div key={key}>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  letterSpacing: "0.5px",
                  marginInlineStart: "20px",
                  fontSize: "1.2rem",
                  marginBlockStart: "10px",
                }}
              >
                {selectedBusiness?.name}
              </Typography>
              <MenuItem
                component={
                  <NavLink to={`/store/${selectedBusiness.id}/check-dates`} />
                }
                //
                icon={<ChecklistIcon />}
              >
                Проверка СГ
                {/* <NewBadge /> */}
              </MenuItem>
              <MenuItem
                component={
                  <NavLink
                    to={`/store/${selectedBusiness.id}/products/page/1/no_quantity`}
                  />
                }
                //
                icon={<QuestionMarkIcon />}
              >
                Артикули без СГ
              </MenuItem>
              <MenuItem
                component={
                  <NavLink
                    to={`/store/${selectedBusiness.id}/products/reference`}
                  />
                }
                icon={<ContentPasteSearchIcon />}
              >
                Справка артикул
              </MenuItem>
              <MenuItem
                component={
                  <NavLink
                    to={`/store/${selectedBusiness.id}/checks/references/page/1`}
                  />
                }
                icon={<ManageSearchIcon />}
              >
                История проверки
              </MenuItem>
              {checkForLoadedPermission(PermissionName.VIEW_PDF_CHECK) && (
                <MenuItem
                  component={
                    <NavLink to={`/store/${selectedBusiness.id}/pdf-checks`} />
                  }
                  icon={<PictureAsPdfIcon />}
                >
                  PDF Проверки
                  {<NewBadge />}
                </MenuItem>
              )}
              {checkForLoadedPermission(PermissionName.VIEW_PRODUCT) && (
                <MenuItem
                  component={
                    <NavLink
                      to={`/store/${selectedBusiness.id}/products/page/1`}
                    />
                  }
                  icon={<QrCode2Icon />}
                >
                  Артикули
                </MenuItem>
              )}
              {checkForLoadedPermission(PermissionName.VIEW_PLANOGRAM) && (
                <MenuItem
                  component={
                    <NavLink to={`/store/${selectedBusiness.id}/planograms`} />
                  }
                  icon={<CalendarViewMonthIcon />}
                >
                  Регали
                </MenuItem>
              )}
              {checkForLoadedPermission(PermissionName.VIEW_EMPLOYEE) && (
                <MenuItem
                  component={
                    <NavLink to={`/store/${selectedBusiness.id}/employees`} />
                  }
                  icon={<PeopleAltIcon />}
                >
                  Служители
                  {/* <HotBadge /> */}
                </MenuItem>
              )}
              {checkForLoadedPermission(PermissionName.VIEW_SUPPLIER) && (
                <MenuItem
                  component={
                    <NavLink to={`/store/${selectedBusiness.id}/suppliers`} />
                  }
                  icon={<LocalShipping />}
                >
                  Доставчици
                </MenuItem>
              )}
              <MenuItem
                component={
                  <NavLink to={`/store/${selectedBusiness.id}/planograms`} />
                }
                icon={<TuneIcon />}
                disabled={true}
              >
                Настройки
              </MenuItem>
            </div>
          )}
          {user?.role === "ROLE_ADMIN" && (
            <>
              <Typography
                variant="body2"
                fontWeight={600}
                style={{
                  letterSpacing: "0.5px",
                  marginInlineStart: "20px",
                  fontSize: "1.2rem",
                  marginBlockStart: "10px",
                }}
              >
                Админ меню
              </Typography>
              <SubMenu icon={<StorefrontIcon />} label="Акаунти">
                <MenuItem
                  component={<Link to="/users" />}
                  icon={<ContactsOutlinedIcon />}
                >
                  Преглед
                </MenuItem>
                <MenuItem
                  component={<Link to="/users/create" />}
                  icon={<ContactsOutlinedIcon />}
                >
                  Създаване
                </MenuItem>
              </SubMenu>
            </>
          )}
          <Typography
            variant="body2"
            fontWeight={600}
            style={{
              letterSpacing: "0.5px",
              marginInlineStart: "20px",
              fontSize: "1.2rem",
              marginBlockStart: "10px",
            }}
          >
            Меню
          </Typography>

          <MenuItem
            component={<NavLink to="/dashboard" />}
            icon={<HomeOutlinedIcon />}
            disabled={true}
          >
            Управление
          </MenuItem>
          <MenuItem
            component={<NavLink to="/stores" />}
            icon={<StorefrontIcon />}
          >
            Обекти
          </MenuItem>
          <MenuItem
            component={<NavLink to="/mailbox" />}
            icon={<MailIcon />}
            disabled={true}
          >
            Съобщения
          </MenuItem>
          <MenuItem
            component={<Link to="/help" />}
            icon={<HelpOutlineOutlinedIcon />}
            disabled={true}
          >
            FAQ
          </MenuItem>
          <MenuItem component={<NavLink to="/profile" />} icon={<AccountBox />}>
            Моят профил
            {<NewBadge />}
          </MenuItem>
          <MenuItem component={<Link to="/logout" />} icon={<LogoutIcon />}>
            Изход
          </MenuItem>
          <p className={style.employeeCode}>
            <b>Код:</b> {user?.employeeCode}
          </p>
        </Menu>
      </Sidebar>
    </div>
  );
}
