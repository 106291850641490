import { redirect } from "react-router";
import Config from "utils/Config";

export async function action({ request }) {
    const data = await request.formData();
    const loginData = {
      email: data.get("email"),
      password: data.get("password"),
      saveEmail: data.get("saveEmail")
    };
    const actionResponse = {
      style: "",
      message: "",
    }

    console.log(loginData);
  
    if (loginData.email === "" || loginData.password === "") {
      actionResponse.style = "danger";
      actionResponse.message = "Моля попълнете всички полета!"
      return actionResponse;
    }
  
    const response = await fetch(Config.apiServerAddress + "/auth/authenticate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(loginData),
    });
  
    if (response.status === 422 || response.status === 401 || response.status === 400) {
      actionResponse.style = "warning";
      actionResponse.message = "Невалидни входни данни, опитайте отново!"
      return actionResponse;
    }
  
    if (!response.ok) {
      actionResponse.style = "danger";
      actionResponse.message = "Грешка в сървъра, опитайте отново!"
      return actionResponse;
    }
  
    const resData = await response.json();
  
    localStorage.clear();
  
    const expiration = new Date();
    expiration.setDate(expiration.getDate() + 30);
    
    localStorage.setItem("token", resData.token);
    localStorage.setItem("expiration", expiration.toISOString());
    localStorage.setItem("user", JSON.stringify(resData.user));
    localStorage.setItem("loginAppVer", Config.appVersion);

    if(loginData.saveEmail !== null){
        localStorage.setItem("email", resData.user.email)
    }
  
    return redirect("/");
  }