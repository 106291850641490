import { AlertTitle, Pagination, PaginationItem } from "@mui/material";
import Alert from "@mui/material/Alert";
import Loading from "components/Loading";
import PageTitle from "components/PageTitle";
import tableStyle from "components/style/DataTable.module.css";
import { TopPageNav } from "components/TopPageNavigation";
import PageContent from "layout/PageContent";
import React, { useEffect, useState } from "react";
import { json, useNavigate, useParams } from "react-router";
import { Link, NavLink } from "react-router-dom";
import { dateEasyRead, formatDateString } from "utils/DateStyler";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

export const ChecksReferences = () => {
  const [checksReferences, setChecksReferences] = useState({ checks: [] , totalPages: 0, totalElements: 0});
  const [loading, setLoading] = useState(true);
  const { page } = useParams();
  const { storeId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const data = await checksReferencesLoader(storeId, page);
      setChecksReferences(data);
      setLoading(false);
    };

    fetchData();
  }, [page]);

  const viewReportHandler = (checkId) => {
    navigate(`/store/${storeId}/checks/references/${checkId}`);
  };

  return (
    <PageContent pageTitle="История проверки">
      <TopPageNav />
      {loading && <Loading />}
      {!loading && checksReferences.checks.length === 0 && <NoChecksFound />}
      {!loading && checksReferences.checks.length > 0 && (
        <ChecksReferencesTable
          checksReferencesData={checksReferences}
          onViewReport={viewReportHandler}
          //   checksReferences={checksReferences}
          page={page}
        />
      )}
    </PageContent>
  );
};

const NoChecksFound = () => {
  return (
    <div className="mb-2">
      <Alert variant="standard" severity="error">
        <AlertTitle>
          <b>Нямате извършени проверки!</b>
        </AlertTitle>
        За този обект все още няма извършени проверки.
      </Alert>
    </div>
  );
};

const ChecksReferencesTable = ({
  checksReferencesData,
  onViewReport,
  page,
}) => {
  const { storeId } = useParams();
  const checksReferences = checksReferencesData.checks;
  const allPages = checksReferencesData.totalPages;
  return (
    <>
      <div className="py-2 table-responsive-xl">
        <table className={tableStyle.table}>
          <thead>
            <tr>
              <th>Начало</th>
              <th>Край</th>
              <th>Проверка на дата</th>
              {/* <th colSpan={2}>Статус</th> */}
              <th>Статус</th>
              <th>Общо артикули</th>
              <th>Проверени</th>
              <th>Време(минути)</th>
              <th>Служител</th>
            </tr>
          </thead>
          <tbody>
            {checksReferences.map((check) => (
              <tr
                key={check.id}
                className={tableStyle.centerMe}
                onClick={() => onViewReport(check.id)}
              >
                <td>{formatTimeStamp(check.startTimestamp)}</td>
                <td>{formatTimeStamp(check.endTimestamp)}</td>
                <td>{dateEasyRead(check.checkDate)}</td>
                {/* <td><i className="fa-solid fa-mobile-screen-button"></i></td> */}
                <td id={formatStatusColor(check.status)}>
                  {formatStatusString(check.status)}
                </td>
                <td
                  id={
                    check.totalProductsCount !== check.checkedProductsCount ?
                    tableStyle.yellowCell : tableStyle.greenCell
                  }
                >
                  {check.totalProductsCount}
                </td>
                <td
                  id={
                    check.totalProductsCount !== check.checkedProductsCount ?
                    tableStyle.yellowCell : tableStyle.greenCell
                  }
                >
                  {check.checkedProductsCount}
                </td>
                <td>
                  {calculateCheckMinutes(
                    check.startTimestamp,
                    check.endTimestamp
                  )}
                </td>
                <td>{check.employeeName}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <p></p>
      <ul className="nav justify-content-center">
        <Pagination
          //   count={props.products.totalPages}
          count={allPages}
          shape="rounded"
          color="primary"
          page={parseInt(page)}
          renderItem={(item) => (
            <PaginationItem
              slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
              component={Link}
              to={`/store/${storeId}/checks/references/page/${
                item.page === 1 ? "1" : item.page
              }`}
              {...item}
            />
          )}
        />
        <p></p>
        <li className="nav-item"></li>
      </ul>
    </>
  );
};

async function checksReferencesLoader(storeId, page) {
  const token = getAuthToken();
  page--;
  const response = await fetch(
    Config.apiServerAddress + `/checks?storeId=${storeId}&size=25&page=${page}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    return [];
  } else {
    const references = await response.json();
    return references;
  }
}

export function calculateCheckMinutes(start, end) {
  const startDate = new Date(start);
  const endDate = new Date(end);
  if (start === null || end === null) {
    return 0;
  }

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    return 0;
  }

  // @ts-ignore
  const difference = endDate - startDate;
  //round to minutes

  return Math.ceil(difference / 1000 / 60);
}
export const formatTimeStamp = (timeStamp) => {
  if (timeStamp === null) {
    return "---";
  }
  //get date in format yyyy-mm-dd and time in format hh:mm - this is timestamp original value 2024/07/02 15:03:18
  var date = timeStamp.split(" ")[0];
  //get date in format yyyy-mm-dd
  date = date.split("/").join("-");
  const time = timeStamp.split(" ")[1].substring(0, 5);

  return (
    <div className={tableStyle.dateAndTimeConatiner}>
      <span className={tableStyle.date}>{formatDateString(date)}</span>
      <span className={tableStyle.time}>{time}</span>
    </div>
  );
};

export const formatStatusString = (status) => {
  switch (status) {
    case "finish":
      return "Завършена";
    case "progress":
      return "Незавършена";
    case "abort":
      return "Прекратена";
    default:
      return "---";
  }
};

const formatStatusColor = (status) => {
  switch (status) {
    case "finish":
      return tableStyle.greenCell;
    case "progress":
      return tableStyle.yellowCell;
    case "abort":
      return tableStyle.redCell;
    default:
      return tableStyle.yellowCell;
  }
};
