const PermissionName = {
    IS_OWNER: 'IS_OWNER',
    VIEW_BUSINESS: 'VIEW_BUSINESS',
    EDIT_BUSINESS: 'EDIT_BUSINESS',
    
    ADD_PRODUCT: 'ADD_PRODUCT',
    VIEW_PRODUCT: 'VIEW_PRODUCT',
    EDIT_PRODUCT: 'EDIT_PRODUCT',
    DISABLE_PRODUCT: 'DISABLE_PRODUCT',
    DELETE_PRODUCT: 'DELETE_PRODUCT',
    
    ADD_EMPLOYEE: 'ADD_EMPLOYEE',
    VIEW_EMPLOYEE: 'VIEW_EMPLOYEE',
    EDIT_EMPLOYEE: 'EDIT_EMPLOYEE',
    DELETE_EMPLOYEE: 'DELETE_EMPLOYEE',
    
    ADD_PLANOGRAM: 'ADD_PLANOGRAM',
    VIEW_PLANOGRAM: 'VIEW_PLANOGRAM',
    EDIT_PLANOGRAM: 'EDIT_PLANOGRAM',
    DELETE_PLANOGRAM: 'DELETE_PLANOGRAM',

    ADD_SUPPLIER: 'ADD_SUPPLIER',
    VIEW_SUPPLIER: 'VIEW_SUPPLIER',
    EDIT_SUPPLIER: 'EDIT_SUPPLIER',
    DELETE_SUPPLIER: 'DELETE_SUPPLIER',

    EXPORT_PDF_CHECK: 'EXPORT_PDF_CHECK',
    IMPORT_PDF_CHECK: 'IMPORT_PDF_CHECK',
    DELETE_PDF_CHECK: 'DELETE_PDF_CHECK',
    VIEW_PDF_CHECK: 'VIEW_PDF_CHECK',

    VIEW_CHECKS: 'VIEW_CHECKS',
    START_CHECK: 'START_CHECK',

    VIEW_CHECKS_HISTORY: 'VIEW_CHECKS_HISTORY'
};

module.exports = PermissionName;