import tableStyle from "components/style/DataTable.module.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import { dateEasyRead } from "utils/DateStyler";
import { formatTimeStamp } from "views/checkDate/ChecksReferences";

import DeleteIcon from "@mui/icons-material/Delete";
import { useState } from "react";
import DeletePdfCheckModal from "./DeletePdfCheckModal";

export default function PendingChecksTable(props) {
  const { storeId } = useParams();
  const navigate = useNavigate();
  const [deleteCheckId, setDeleteCheckId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const deleteCheckHandler = (checkId) => {
    setDeleteCheckId(checkId);
    setShowDeleteModal(true);
  };
  const closeDeleteModal = () => {
    setDeleteCheckId(null);
    setShowDeleteModal(false);
  };

  const previewPdfCheck = (checkId) => {
    navigate(`/store/${storeId}/pdf-checks/${checkId}`);
  };

  return (
    <div className="py-2 table-responsive table-responsive-xl">
      {showDeleteModal && (
        <DeletePdfCheckModal
          deleteProduct={{ id: deleteCheckId }}
          onCancel={closeDeleteModal}
        />
      )}
      <table className={tableStyle.table}>
        <thead>
          <tr>
            <th>&#8470;</th>
            <th>Артикули</th>
            <th>От дата</th>
            <th>Изпълнение</th>
            <th>Генерирана от</th>
            <th>Действие</th>
          </tr>
        </thead>
        <tbody>
          {props.pendingChecks.map((check, index) => (
            // <Link to={`/store/${storeId}/pdf-checks/${check.checkNumber}`}>
            <tr
              key={index}
              className={tableStyle.centerMe}
              onClick={() => previewPdfCheck(check.id)}
            >
              <td className={tableStyle.dateCell}>{check.id}</td>
              <td>{check.productsCount}</td>
              <td>{formatTimeStamp(check.generationTimestamp)}</td>
              <td
                className={tableStyle.dateCell}
                id={getCellColor(check.executionDate)}
              >
                {dateEasyRead(check.executionDate)}
              </td>
              <td>
                {check.employee.user.firstName +
                  " " +
                  check.employee.user.lastName}
              </td>
              <td>
                <span id="alignCenter">
                  <Link
                    className={tableStyle.iconButton}
                    to={""}
                    onClick={(event) => {
                      event.stopPropagation();
                      deleteCheckHandler(check.id);
                    }}
                  >
                    <DeleteIcon color="error" />
                  </Link>
                </span>
              </td>
            </tr>
            // </Link>
          ))}
        </tbody>
      </table>
    </div>
  );
}

function getCellColor(date) {
  const theDate = new Date(date);

  const currentDate = new Date();
  var outDatedDate = new Date();
  outDatedDate.setDate(currentDate.getDate() - 10);
  console.log(outDatedDate + " ---->>> " + theDate);
  const past = theDate < currentDate;
  const outDated = theDate > outDatedDate;

  if (theDate < outDatedDate) return tableStyle.redCell;
  if (theDate.toDateString() === currentDate.toDateString() || past)
    return tableStyle.greenCell;
  return tableStyle.yellowCell;
}
