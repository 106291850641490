import { useMediaQuery } from "@mui/material";
import style from "./style/NavbarLogo.module.css";
import Config from "utils/Config";

export default function NavbarLogo() {
  const matches = useMediaQuery("(min-width: 400px)");
  const appName = "ExpireMate";
  // const appName = matches ? "ExpireMate" : "EM";

  return (
    <div className={style.container}>
      <p className={style.appName}>{appName}</p>
      <p className={style.appVersion}>ver {Config.appVersion}</p>
    </div>
  );
}