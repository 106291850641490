import React from "react";
import style from "./style/PageTitle.module.css";

const PageTitle = (props) => {
  return (
    <React.Fragment>
      <div className={style.container}>
        <p className={style.pageTitle}>{props.title}</p>
        <span className={style.moreInfo}>{props.info}</span>
      </div>
    </React.Fragment>
  );
};

export default PageTitle;
