import { useNavigate } from "react-router";
import { checkAuthLoader, getUser } from "./auth";
import { useEffect } from "react";

function Dispatch () {
    
    const user = getUser();
    const navigate = useNavigate();

    useEffect(()=>{
        checkAuthLoader();

        if(user === null || !user?.role){
            return navigate("/logout");
        }
    
        if(user?.role === "ROLE_ADMIN"){
            return navigate("/users");
        }
    
        if(user?.role === "ROLE_USER"){
            return navigate("/stores");
        }
    },[user, navigate])

    

}


export default Dispatch;