import { Link, useParams } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import tableStyle from "components/style/DataTable.module.css";

import style from "./style/PlanogramsList.module.css";
import { useState } from "react";
import NoEntityAlert from "components/NoEntityAlert";
import { checkForLoadedPermission } from "utils/auth";
import PermissionName from "utils/enums/PermissionName";
import { formatCellNullValue } from "utils/CellDataFormater";

export default function PlanogramsList(props) {
  const { storeId } = useParams();
  const selectedBusiness = JSON.parse(localStorage.getItem("selectedBusiness"));

  const addPermissions = checkForLoadedPermission(
    PermissionName.ADD_SUPPLIER
  );
  const editPermissions = checkForLoadedPermission(
    PermissionName.EDIT_SUPPLIER
  );
  const deletePermissions = checkForLoadedPermission(
    PermissionName.DELETE_SUPPLIER
  );

  const [moveProductsModal, setMoveProductsModal] = useState(false);
  const [deletePlanogramModal, setDeletePlanogramModal] = useState(false);
  const [deletePlanogram, setDeletePlanogram] = useState(0);
  const planogramsCount = props.planograms?.length;

  const showModal = (id, products) => {
    setDeletePlanogram(id);

    if (products === 0) {
      setDeletePlanogramModal(true);
    } else {
      setMoveProductsModal(true);
    }
  };

  const closeModal = () => {
    setDeletePlanogramModal(false);
    setMoveProductsModal(false);
  };

  return (
    <>
      {props.supliers?.length === 0 && addPermissions && (
        <NoEntityAlert
          heading="Нямате добавени доставчици!"
          toLink={" "}
          onClickGo={() => props.openCreateModal()}
          onClickGoText="Добави доставчик"
        >
          Към този обект няма добавени доставчици.
        </NoEntityAlert>
      )}
      {props.supliers?.length === 0 && !addPermissions && (
        <NoEntityAlert
          heading="Нямате добавени доставчици!"
          toLink={" "}
        >
          Към този обект няма добавени доставчици.
        </NoEntityAlert>
      )}
      {/* {deletePlanogramModal && (
        <ConfirmDeletePlanogramModal
          deletePlanogram={deletePlanogram}
          onCancel={() => closeModal()}
        />
      )}
      {moveProductsModal && (
        <MoveProducts
          planogramsList={props.planograms}
          deletePlanogram={deletePlanogram}
          onCancel={() => closeModal()}
        />
      )} */}
      {props.supliers?.length > 0 && (
        <div className="py-2 table-responsive">
          <table className={`${tableStyle.table}  table-xl`}>
            <thead>
              <tr>
                <th>&#8470;</th>
                <th>Име</th>
                <th>Артикули</th>
                <th>Фирма</th>
                <th>Лице за контакт</th>
                <th>Телефон</th>
                <th>Имейл</th>
                {(editPermissions || deletePermissions) && <th>Действие</th>}
              </tr>
            </thead>
            <tbody>
              {props.supliers?.map((suplier, index) => (
                <tr key={suplier.id}>
                  <td id={tableStyle.alignCenter}>{index + 1}</td>
                  <td>{formatCellNullValue(suplier.name, "N/A")}</td>
                  <td className={tableStyle.centerMe}>
                    {suplier.productsCount}
                  </td>
                  <td>{formatCellNullValue(suplier.companyName, "N/A")}</td>
                  <td>{formatCellNullValue(suplier.contactName, "N/A")}</td>
                  <td>{formatCellNullValue(suplier.mobilePhone, "N/A")}</td>
                  <td>{formatCellNullValue(suplier.email, "N/A")}</td>
                  {(editPermissions ||
                    deletePermissions) && (
                      <td className={tableStyle.centerMe}>
                        <>
                          {deletePermissions && (
                            <Link className={style.iconButton} to={" "} onClick={() => props.openEditModal(suplier.id)}>
                              <EditIcon color="primary" />
                            </Link>
                          )}
                          {deletePermissions && (
                            <Link className={style.iconButton} to={" "}  onClick={() => props.openDeleteModal(suplier.id, suplier.name)}>
                              <DeleteIcon color="error" />
                            </Link>
                          )}
                        </>
                      </td>
                    )}
                </tr>
              ))}
            </tbody>
          </table>
          <p></p>
        </div>
      )}
    </>
  );
}
