import { convertToISODate } from "utils/DateFormater";
import { generateRandom8Digits } from "../loaders/StartCheckLoader";

const { redirect } = require("react-router");
const { getAuthToken } = require("utils/auth");
const Config = require("utils/Config");

export async function action({ request }) {
  const formData = await request.formData();
  const token = getAuthToken();
  const currentCheck = JSON.parse(localStorage.getItem("currentCheck"));
  const checkData = JSON.parse(localStorage.getItem("openProduct"));
  checkData.submitTime = new Date().toISOString();
  const rand = generateRandom8Digits();

  const productExpireData = {
    storeId: formData.get("storeId"),
    productId: formData.get("productId"),
    disableProduct: formData.get("disableProduct") === null ? false : true,
    currentExpireDate: formData.get("currentExpireDate"),
    expiredFound: formData.get("expiredFound") === null ? false : true,
    noQuantity: formData.get("noQuantity") === null ? false : true,
    checkId: currentCheck.id,
    supplierId: formData.get("supplierId"),
    expireDate:
      formData.get("expireDate") === null
        ? formData.get("currentExpireDate")
        : formData.get("expireDate"),
        // : convertToISODate(formData.get("expireDate")),
    warnDays:
      formData.get("warnDays") === null ? "0" : formData.get("warnDays"),
    checkData: checkData,
  };

  if(productExpireData.expiredFound === true && productExpireData.warnDays === 0){
      productExpireData.warnDays = 1;
      productExpireData.expireDate = productExpireData.currentExpireDate;
  }

  const fetchResponse = await fetch(Config.apiServerAddress + "/expires", {
    method: "PUT",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(productExpireData),
  });

  localStorage.removeItem("openProduct");

  if (!fetchResponse.ok) {
    throw new Error("Update failed: " + fetchResponse.body);
  }
  currentCheck.checkedProductsCount++;

  if (currentCheck.totalProductsCount === currentCheck.checkedProductsCount) {
    try {
      await saveCheckData(currentCheck);
      const checkId = currentCheck.id;
      localStorage.removeItem("currentCheck");
      localStorage.setItem("checkStatus", "check-done");
      return redirect(`/store/${productExpireData.storeId}/checks/references/${checkId}`)
    } catch (error) {
      throw new Error("Update failed "+error);
    }
  }

  localStorage.setItem("currentCheck", JSON.stringify(currentCheck));
  return redirect(`/store/${productExpireData.storeId}/check-dates/check-product/${rand}`);
}


async function saveCheckData(checkData) {
  const token = getAuthToken();

  // const parsedCheckData = JSON.parse(checkData);
  checkData.status = "finish";

  const fetchResponse = await fetch(
    Config.apiServerAddress + "/expires/currentCheck?action=finish",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(checkData),
    }
  );

  if (!fetchResponse.ok) {
    throw new Error("Update failed");
  }
}
