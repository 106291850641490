import React, { useState } from "react";

export const PermissionContext = React.createContext({
//   permissions: null,
  setStorePermissions: (storePermissions) => {},
  havePermission: () => {},
});

export const PermissionContextProvider = (props) => {
  // const [permissionModalVisible, setPermissionModalVisible] = useState(false);
  const [permissions, setPermissions] = useState(null);

  const havePermissionHandler = () => {
    return false;
  };

  const setStorePermissionsHandler = (storePermissions) => {
    setPermissions(storePermissions);
  };

  return (
    <PermissionContext.Provider
      value={{
        // permissions: permissions,
        setStorePermissions: setStorePermissionsHandler,
        havePermission: havePermissionHandler,
      }}
    >
      {props.children}
    </PermissionContext.Provider>
  );
};
