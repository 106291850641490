import { Link } from "react-router-dom";
import style from "./style/NoEntityAlert.module.css";

export default function NoEntityAlert(props) {
  return (
    <div className={`alert alert-warning ${style.mainContainer}`} role="alert">
      <h4 className="alert-heading">{props.heading}</h4>
      <p>
        {props.children}
      </p>
      <hr />
      <p className="mb-0 text-center">
        <Link className="btn btn-warning ms-1" onClick={props.onClickGo} to={props.toLink}>
          {props.onClickGoText}
        </Link>
      </p>
    </div>
  );
}