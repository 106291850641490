import { Alert } from "@mui/material";
import Modal from "components/Modal";
import { redirect, useNavigate, useParams } from "react-router";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

export default function DeleteSupplierModal(props) {
  // const navigate = useNavigate();
  const { storeId } = useParams();

  const handleCancel = () => {
    props.onCancel();
  };
  const handleDelete = async (event) => {
    event.preventDefault();
  
    const token = getAuthToken();
  
    const response = await fetch(
      Config.apiServerAddress + `/suppliers?storeId=${storeId}&supplierId=${props.supplier.id}`,
      {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    localStorage.setItem("supplierDeleted", response.ok ? "true" : "false");
  
    props.onCancel();
  };
  return (
    <Modal>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLongTitle">
            Изтриване на доставчик
          </h4>
        </div>
        <div className="modal-body my-3 ms-3">
          <Alert variant="filled" severity="warning">
            На път сте да изтриете доставчика <b>{props.supplier.name}</b>!
            <br /><br />
            Желаете ли да премахнете този доставчик от вашият обект?
          </Alert>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary ms-1"
            data-dismiss="modal"
            onClick={handleCancel}
          >
            Откажи
          </button>
          <button
            type="button"
            className="btn btn-danger ms-1"
            onClick={handleDelete}
          >
            Изтрий
          </button>
        </div>
      </div>
    </Modal>
  );
}
