import { Menu, MenuItem, useProSidebar } from "react-pro-sidebar";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ProfileCorner from "./ProfileCorner";
import NavbarLogo from "./NavbarLogo";
import style from "./style/Navbar.module.css";
import { useEffect } from "react";

export default function Navbar() {
  const { collapseSidebar,  } = useProSidebar();
  // const selectedBusiness = JSON.parse(localStorage.getItem("selectedBusiness"));

  const onToggleSideBar = () => {
    collapseSidebar();
  };

  return (
    <nav className={"navbar navbar-light bg-light"}>
      <div className={style.leftContainer}>
      <Menu>
        <MenuItem icon={<MenuOutlinedIcon />} onClick={onToggleSideBar} className={style.sidebarToggler}/>
      </Menu>
      <NavbarLogo />
      </div>
      <ProfileCorner />
    </nav>
  );
}
