import { Link, redirect, useNavigate } from "react-router-dom";
import "./style/BusinessesList.css";

import BusinessListCard from "./BusinessListCard";
import Loading from "../../components/Loading";
import PageContent from "layout/PageContent";

import { Suspense } from "react";
import { useLoaderData, json, defer, Await } from "react-router-dom";
import { TopPageNav, TopPageNavItem } from "components/TopPageNavigation";
import NoEntityAlert from "components/NoEntityAlert";
import ImportantMessageModal from "layout/ImportantMessageModal";

export default function BusinessesList() {
  // @ts-ignore
  const { businesses } = useLoaderData();
  const navigate = useNavigate();

  const businessAutoLoader = (businessList) => {
    return navigate(`/store/${businessList[0].id}/select`);
  };

  return (
    <PageContent pageTitle="Обекти">
      <ImportantMessageModal />
      <TopPageNav>
        <TopPageNavItem>
          <Link className="btn btn-success ms-1 btn-sm" to={"/create-business"}>
            Добави обект
          </Link>
        </TopPageNavItem>
      </TopPageNav>
      <Suspense fallback={<Loading />}>
        <div className="container mt-2" style={{ textAlign: "left" }}>
          <div className="row">
            <Await resolve={businesses}>
              {(loadBusinessesList) => {
                if (loadBusinessesList.length === 0) {
                  return (
                    <NoEntityAlert
                      heading="Нямате добавен обект!"
                      toLink={`/getStarted`}
                      onClickGoText="Създай нов"
                    >
                      Моля добавете обект за да започнете!
                    </NoEntityAlert>
                  );
                }

                if (
                  loadBusinessesList.length === 1 &&
                  !localStorage.getItem("selectedBusiness")
                ) {
                  return businessAutoLoader(loadBusinessesList);
                } else {
                  return loadBusinessesList?.map((theStore, index) => (
                    <BusinessListCard key={index} store={theStore} />
                  ));
                }
              }}
            </Await>
          </div>
        </div>
      </Suspense>
    </PageContent>
  );
}
