import { redirect } from "react-router";
import PermissionName from "./enums/PermissionName";
import Config from "./Config";
export function getAuthToken() {
  const token = localStorage.getItem("token");
  const loginAppVer = localStorage.getItem("loginAppVer");
  const tokenDuration = getTokenDuration(); // Вземаме tokenDuration при всяко извикване на getAuthToken()

  if (!token) {
    return redirect("/logout");;
  }

  if(loginAppVer !== Config.appVersion){
    return redirect("/logout");
  }

  if (tokenDuration < 0) {
    return redirect("/logout");;
  }

  return token;
}

export function getTokenDuration() {
  const storedExpirationDate = localStorage.getItem("expiration");
  const expirationDate = new Date(storedExpirationDate);
  const now = new Date();
  const duration = expirationDate.getTime() - now.getTime();

  return duration;
}

const tokenDuration = getTokenDuration();
export function logout() {
  var email;
  if(localStorage.getItem("email")){
    email = localStorage.getItem("email");
  }
  localStorage.clear();
  if(email !== undefined){
    localStorage.setItem("email", email);
  }
}

export function tokenLoader() {
  return getAuthToken();
}

export function getUser() {
  checkAuthLoader();
  return JSON.parse(localStorage.getItem("user"));
}

export function isUserLogged() {
  const token = getAuthToken();

  if (!token || token === null || token === "EXPIRED" || tokenDuration < 0) {
    return false;
  }

  return true;
}

export function checkAuthLoader() {
  const token = getAuthToken();
  const user = JSON.parse(localStorage.getItem("user"));

  if ((!token || token === null || token === "EXPIRED") || (!user || user === null)) {
    console.log("redirect logout")
    return redirect("/logout");
  }
}

export function checkForLoadedPermission(needPermission){

  const storeData = JSON.parse(localStorage.getItem("selectedBusiness"));
  const permissions = storeData.permissions;

  if(permissions.includes(needPermission) || permissions.includes(PermissionName.IS_OWNER)){
    return true;
  }
  return false;

}
