import { json, redirect } from "react-router";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

export async function action({ request }) {
    const token = getAuthToken();
  
    const data = await request.formData();
    const response = {
      code: "",
      message: "",
    };
    const newUserData = {
      firstName: data.get("firstName"),
      lastName: data.get("lastName"),
      email: data.get("email"),
      mobilePhone: data.get("mobilePhone"),
      cityId: data.get("cityId"),
      genderId: data.get("genderId"),
      type: data.get("type"),
    };
  
    console.log(newUserData);
  
    const fetchResponse = await fetch(
      Config.apiServerAddress + "/users",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newUserData),
      }
    );
  
    if (fetchResponse.status === 422 || fetchResponse.status === 401) {
      response.code = "422";
      response.message = "Нещо се обърка. Опитайте отново!";
      return response;
    }
  
    if (!fetchResponse.ok) {
      throw json({ message: "Authentication failed!" }, { status: 500 });
    }
  
    return redirect("/users");
  }