import Modal from "components/Modal";
import { useEffect, useState } from "react";
import { useActionData, Form, useNavigate, useParams } from "react-router-dom";
import Config from "utils/Config";
import { checkForLoadedPermission, getAuthToken } from "utils/auth";
import PermissionName from "utils/enums/PermissionName";
import style from "./style/CreatePlanogram.module.css";

// @ts-ignore

export default function AddSuplierModal(props) {
  const navigate = useNavigate();
  const actionData = useActionData();
  const { storeId } = useParams();
  const [showMessage, setShowMessage] = useState(false);
  const [message, setMessage] = useState({ code: null, text: null });

  useEffect(() => {
    if (!checkForLoadedPermission(PermissionName.ADD_PLANOGRAM)) {
      return navigate(`/store/${storeId}`);
    }
  }, []);

  const showMessageHandler = () => {
    setShowMessage(true);
    setTimeout(() => {
      setShowMessage(false);
      setMessage({ code: null, text: null });
    }, 1700);
  };

  async function createPlanogramHandler(event) {
    event.preventDefault();
    const token = getAuthToken();
    const formData = new FormData(event.target);

    const newSuplierData = {
      storeId: formData.get("storeId"),
      name: formData.get("name"),
      companyName: formData.get("companyName"),
      bulstat: formData.get("bulstat"),
      contactName: formData.get("contactName"),
      mobilePhone: formData.get("mobilePhone"),
      email: formData.get("email"),
    };

    if (
      typeof newSuplierData.name === "string" &&
      newSuplierData.name.length === 0
    ) {
      setMessage({
        code: "422",
        text: "Моля въведете фирмата на доставчика!",
      });
      return showMessageHandler();
    } else if (
      typeof newSuplierData.name === "string" &&
      newSuplierData.name.length < 3
    ) {
      setMessage({
        code: "422",
        text: "Името на фирмата е твърде късо!",
      });
      return showMessageHandler();
    }

    const fetchResponse = await fetch(Config.apiServerAddress + "/suppliers", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(newSuplierData),
    });

    // const responseBody = await fetchResponse.json();
    if (fetchResponse.status === 422 || fetchResponse.status === 401) {
      setMessage({
        code: fetchResponse.status,
        text: "Нещо се обърка. Опитайте отново!",
      });
      showMessageHandler();
    } else if (fetchResponse.status === 400) {
      setMessage({
        code: fetchResponse.status,
        text: "Този доставчик вече е добавен към обекта ви!",
      });
      showMessageHandler();
    } else {

      localStorage.setItem("supplierCreated", "true");

      props.onClose();
    }
  }

  return (
    <Modal>
      <Form onSubmit={createPlanogramHandler}>
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title" id="exampleModalLongTitle">
              Добавяне на доставчик към обекта
            </h4>
          </div>
          <div className="modal-body my-3 ms-3">
            {showMessage &&
              // @ts-ignore
              message.code !== "200" && (
                <div
                  className="alert alert-danger"
                  id={style.messageBox}
                  role="alert"
                >
                  {
                    // @ts-ignore
                    message.text
                  }
                </div>
              )}
            {showMessage &&
              // @ts-ignore
              message.code === "200" && (
                <div
                  className="alert alert-success"
                  id={style.messageBox}
                  role="alert"
                >
                  {
                    // @ts-ignore
                    message.text
                  }
                </div>
              )}

            <label htmlFor="suplierName" className="form-label">
              Име на фирмата* ("<b> Бързоноско </b>" ООД)
            </label>
            <input
              type={"text"}
              className="form-control mb-3"
              name="name"
              placeholder="Въведете име на фирмата"
              required
            />
            <input type="hidden" name="supplierId" value={props.supplierId} />
            <input type="hidden" name="storeId" value={storeId} />
            <label htmlFor="product-name" className="form-label">
              Юридическо лице (<b>"Бързоноско" ООД</b>)
            </label>
            <input
              type={"text"}
              className="form-control mb-3"
              name="companyName"
              placeholder="Въведете пълното име на фирмата"
            />
            <label htmlFor="bulstat" className="form-label">
              Булстат
            </label>
            <input
              type={"text"}
              className="form-control mb-3"
              name="bulstat"
              placeholder="Въведете булстат на фирмата"
            />
            <label htmlFor="contactName" className="form-label">
              Лице за контакт
            </label>
            <input
              type={"text"}
              className="form-control mb-3"
              name="contactName"
              placeholder="Въведете лице за контакт"
            />
            <label htmlFor="mobilePhone" className="form-label">
              Телефон
            </label>
            <input
              type={"text"}
              className="form-control mb-3"
              name="mobilePhone"
              placeholder="Въведете телефон за контакт"
            />
            <label htmlFor="email" className="form-label">
              Имейл
            </label>
            <input
              type={"text"}
              className="form-control mb-3"
              name="email"
              placeholder="Въведете имейл за контакт"
            />
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-outline-secondary ms-1"
              data-dismiss="modal"
              onClick={props.onClose}
            >
              Обратно
            </button>
            <button type="submit" className="btn btn-success ms-1">
              Добави
            </button>
          </div>
        </div>
      </Form>
    </Modal>
  );
}
