import React, { Suspense, useEffect, useState } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { Await, json, useLoaderData, useLocation } from "react-router-dom";
import ProductsCheckDocument from "./ProductsCheckDocument";
import { dateEasyRead } from "utils/DateStyler";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";
import DataLoading from "./DataLoading";
import Loading from "./Loading";

export default function ExportPdf(props) {
  const location = useLocation();
  const { state } = location;
  const documentData = state ? state.documentData : {};
  const [returnedData, setReturnedData] = useState([]);
  // const [loading, setLoading] = useState(true);
  const hasFetched = localStorage.getItem("hasFetched");

  // const [documentData, setDocumentData] = useState({});

  // useEffect(() => {
  //   generatePdfCheck(props.documentData).then((data) => {
  //     setReturnedData(data);
  //     setLoading(false);
  //   });
  // }, []); // Add documentData as a dependency to ensure it's stable

  // useEffect(() => {
  //   if (!hasFetched) {
  //     localStorage.setItem("hasFetched", "true");
  //     generatePdfCheck(documentData).then((data) => {
  //       setReturnedData(data);
  //       setLoading(false);
  //     });

  //     // Clean up
  //     return () => {
  //       localStorage.removeItem("hasFetched");
  //     };
  //   }
  // }, [documentData, hasFetched]); // Add documentData as a dependency to ensure it's stable

  return (
    <>
      {/* {loading && <Loading />}
      {!loading && (
      )} */}
        <PDFViewer width={"100%"} height={"1000px"}>
          <ProductsCheckDocument documentData={documentData} />
        </PDFViewer>
    </>
  );
}

export async function generatePdfCheck(documentData) {
  console.log("Генериране на проверка в PDF формат");
  const token = getAuthToken();

  const response = await fetch(
    Config.apiServerAddress + "/pdfchecks/generate",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(documentData),
    }
  );

  if (!response.ok) {
    throw json(
      { message: "Could not generate PDF check." },
      {
        status: 500,
      }
    );
  } else {
    return await response.json();
  }
}
