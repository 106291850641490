import { Alert, AlertTitle } from "@mui/material";
import Modal from "components/Modal";
import { redirect, useNavigate, useParams } from "react-router";
import { getAuthToken } from "utils/auth";
import Config from "utils/Config";

export default function DeletePdfCheckModal(props) {
  const navigate = useNavigate();
  const { storeId } = useParams();

  const handleCancel = () => {
    props.onCancel();
  };
  const handleSubmit = (event) => {
    event.preventDefault();
    props.onCancel();
    deletePdfCheck(props.deleteProduct.id, storeId).then(() => {
      return navigate(`/store/${storeId}/pdf-checks`);
    });
  };
  return (
    <Modal>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLongTitle">
            Изтриване на не въведана проверка
          </h4>
        </div>
        <div className="modal-body my-3 ms-3">
          <Alert severity="warning">
            <AlertTitle>Внимание!</AlertTitle>
            На път сте да изтриете PDF проверка с номер{" "}
            <b>{props.deleteProduct.id}</b>! След потвърждение, тя ще бъде
            изтрита и няма да можете да я въведете отново!
          </Alert>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary ms-1"
            data-dismiss="modal"
            onClick={handleCancel}
          >
            Откажи
          </button>
          <button
            type="button"
            className="btn btn-danger ms-1"
            onClick={handleSubmit}
          >
            Изтрий
          </button>
        </div>
      </div>
    </Modal>
  );
}

async function deletePdfCheck(checkId, storeId) {
  const token = getAuthToken();

  const response = await fetch(
    Config.apiServerAddress + `/pdfchecks?storeId=${storeId}&checkId=${checkId}`,
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  localStorage.setItem("pdfCheckDeleted", response.ok ? "true" : "false");

  return response;
}
