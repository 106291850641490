import { Link } from "react-router-dom";
import style from "./style/BusinessListCard.module.css";
import image from "../../assets/images/storefront.jpg"
import { formatDateString } from "utils/DateStyler";

const BusinessListCard = (props) => {
  const ratingStars = [];
  let ratingClass = "rating-" + props.store.rating;
  // let thumbnailSrc;
  // // let fileName      = "1683352612684-file.png";
  // if (!props.store.image?.name) {
  //   // thumbnailSrc = Config.apiServerAddress + "/images/thumbs/no_image.png";
  //   thumbnailSrc =
  //     "https://media.istockphoto.com/id/1314210006/photo/grocery-store-shop-in-vintage-style-with-fruit-and-vegetables-crates-on-the-street.jpg?s=612x612&w=0&k=20&c=UFL3bRQkWH7dt6EMLswvM4u8-1sPQU9T5IFHXuBbClU=";
  //   // "https://www.hearthsidedistributors.com/site/hearthgrillsales/images/noimage.png";
  // } else {
  //   // thumbnailSrc  = Config.apiServerAddress + "/images/thumbs/" + props.store.image.name;
  //   thumbnailSrc =;
  // }

  for (let i = 0; i < 6; i++) {
    if (i < props.store.rating) {
      ratingStars.push(<i className="fa fa-star"></i>);
    } else {
      ratingStars.push(<i className="fa-regular fa-star"></i>);
    }
  }

  return (
    <div
      className={`${style.card} card p-3 mb-3 border shadow flex`}
      key={props.theKey}
    >
      <div className="d-flex flex-column ml-2">
        <span className="store-name">{props.store.name}</span>
        <span className={ratingClass}>
          {ratingStars} {props.store.rating}/6
        </span>
        <span className="text-black-80">
          {props.store.city}, {props.store.address}
        </span>
        <div className={style.buttonsGroup}>
          <Link
            className="btn btn-success btn-sm ms-2"
            to={`/store/${props.store.id}/select`}
          >
            Отвори
          </Link>
        </div>
      </div>
      <div className="">
        <p className={style.infoP}>Общо артикули: <b>{props.store.totalProducts}</b></p>
        <p className={style.infoP}>Регали: <b>{props.store.totalPlanograms}</b></p>
        <p className={style.infoP}>Служители: <b>{props.store.totalEmployees}</b></p>
        <p className={style.infoP}>Доставчици: <b>{props.store.totalSuppliers}</b></p>
        {/* <p className={style.infoP}>Следваща проверка: <b>{formatDateString(latestCheckDate)}</b></p>   */}
      </div>
      <div className={style.businessThumb}>
        {/* <img className="rounded" style={{width: ""}} src={props.store.imageLink} /> */}
        <img
          className="rounded"
          style={{ width: "100%" }}
          src={image}
          alt=" "
        />
      </div>
    </div>
  );
};

function findLatestCheckDate(objects) {
  if (!objects || objects.length === 0) {
      return null; // връщаме null, ако няма обекти в списъка
  }
  
  let latestDate = null;

  // Обхождаме всеки обект в списъка
  objects.forEach(obj => {
      if (obj.expire && obj.expire.checkDate) {
          const checkDate = obj.expire.checkDate;
          // Проверяваме дали checkDate е по-късна от текущата latestDate
          if (!latestDate || checkDate < latestDate) {
              latestDate = checkDate;
          }
      }
  });

  return latestDate;
}

export default BusinessListCard;
