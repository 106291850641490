import { getAuthToken, getUser } from "utils/auth";
import Modal from "../components/Modal";
import { useEffect, useState } from "react";
import Config from "utils/Config";

export default function ImportantMessageModal() {
  const [importantMessage, setImportantMessage] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  const fetchImportantMessage = async () => {
    try {
      const message = await getImportantMessage();
      if (message.id !== null && message.id !== undefined) {
        setImportantMessage(message);
        setModalVisible(true);
      }
    } catch (error) {
      return null;
    }
  };

  useEffect(() => {
    fetchImportantMessage();
  }, []);

  const confirmMessageHandler = async (importantMessage) => {
    setModalVisible(false);
    await confirmMessage(importantMessage.id);
    fetchImportantMessage();
  };

  const renderHTML = (html) => {
    return { __html: html };
  };

  return (
    <>
      {modalVisible && (
        <Modal onClose={confirmMessageHandler}>
          {importantMessage && (
            <div className="modal-content">
              <div className="modal-header">
                <h2 className="modal-title" id="exampleModalLongTitle">
                  {importantMessage?.title}
                </h2>
              </div>
              <div className="modal-body my-3 ms-3">
                <div
                  dangerouslySetInnerHTML={renderHTML(
                    importantMessage?.message
                  )}
                />
              </div>
              <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-outline-success ms-1"
                  onClick={() => confirmMessageHandler(importantMessage)}
                >
                  Разбрах
                </button>
              </div>
            </div>
          )}
        </Modal>
      )}
    </>
  );
}

async function getImportantMessage() {
  const token = getAuthToken();

  const response = await fetch(
    Config.apiServerAddress + `/important-messages`,
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.json();
}

async function confirmMessage(messageId) {
  const token = getAuthToken();
  await fetch(
    Config.apiServerAddress + `/important-messages?messageId=${messageId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return true;
}
