// @ts-nocheck
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import Config from "../../utils/Config";
import PageTitle from "../../components/PageTitle";
import "./style/AddNewBusiness.css";
import { getAuthToken } from "utils/auth";

const AddNewBusiness = () => {
  let navigate = useNavigate();

  const [addNewStoreDTO, setAddNewBusinessDTO] = useState();
  const [cities, setCities] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const token = getAuthToken();

  useEffect(() => {
    const loadCities = async () => {
      const request = await fetch(Config.apiServerAddress + "/cities", {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`
        },
      });
      
      const result = await request.json();
      setCities(result);
    };
  
    loadCities();
  }, [token]); // Добавете тук зависимост само за token, ако е нужно
  

  const onInputChange = (e) => {
    setAddNewBusinessDTO((prevState) => {
      return { ...prevState, [e.target.name]: e.target.value };
    });
  };

  const onImageUpload = (e) => {
    e.preventDefault();
    setSelectedImage(e.target.files[0]);

    // setAddNewStoreDTO((prevState) => {
    //   return { ...prevState, image: e.target.files[0] };
    // });
  };

  const onSubmit = async (e) => {
    e.preventDefault();

    console.log("*** FORM SUBMITED ***");
    console.log(JSON.stringify(addNewStoreDTO));

    const formData = new FormData();

    formData.append(`image`, selectedImage);

    for ( var key in addNewStoreDTO ) {
      formData.append(key, addNewStoreDTO[key]);
  }
    // formData.append(`storeDTO`, JSON.stringify(addNewStoreDTO));

    const requestOptions = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`
      },
      body: JSON.stringify(addNewStoreDTO),
    };

    fetch(Config.apiServerAddress + "/stores", requestOptions)
      .then(async (response) => {
        const isJson = response.headers
          .get("content-type")
          ?.includes("application/json");
        const data = isJson && (await response.json());

        // check for error response
        if (!response.ok) {
          // get error message
          const error = (data && data.message) || response.status;
          // setFileUploadResponse(data.message);
          return Promise.reject(error);
        }

        navigate("/stores");
      })
      .catch((error) => {
        console.error("Error while uploading file!", error);
      });

    // await http.post("http://localhost:8080/stores", formData);
  };

  return (
    <div className="container">
      <PageTitle title="Добавяне на търговски обект" />
      <div className="row justify-content-center align-items-center py-4">
        <div className="col-12 col-lg-9 col-xl-7">
          <div className="card shadow-2-strong card-registration">
            <div className="card-body p-4 p-md-5">
              <form onSubmit={(e) => onSubmit(e)}>
                <div className="row">
                  <div className="col-md-6 mb-4">
                    <div className="form-outline">
                      <input
                        type="text"
                        name="businessName"
                        placeholder="Име на търговския обект"
                        className="form-control form-control-lg"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                  <div className="col mb-4">
                    <select
                      className="select form-control-lg"
                      name="categoryId"
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="0" disabled selected>
                        Бизнес категория
                      </option>
                      <option value="1">Малък хранителен магазин</option>
                      <option value="2">Супермаркет</option>
                      <option value="3">Хипермаркет</option>
                      <option value="4">Склад</option>
                      <option value="5">Ресторант</option>
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6 mb-4 pb-2">
                    <div className="form-outline">
                      <input
                        type="text"
                        name="postCode"
                        placeholder="Пощенски код"
                        className="form-control form-control-lg"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>

                  <div className="col mb-4">
                    <select
                      className="select form-control-lg"
                      name="cityId"
                      onChange={(e) => onInputChange(e)}
                    >
                      <option value="0" disabled selected>
                        Град
                      </option>
                      {cities.map((city, index) => (
                        <option key={index} value={city.id}>
                          {city.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>

                <div className="row">
                  <div className="col mb-4">
                    <div className="form-outline">
                      <textarea
                        rows="4"
                        cols="40"
                        name="address"
                        placeholder="Адрес на обекта"
                        className="form-control form-control-lg"
                        onChange={(e) => onInputChange(e)}
                      />
                    </div>
                  </div>
                </div>
                <div className="mt-4 pt-2">
                  <input
                    className="btn btn-success btn-lg mx-2"
                    type="submit"
                    value="Добави"
                  />
                  <Link className="btn btn-danger btn-lg mx-2" to={'..'}>
                    Откажи
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewBusiness;
