import { redirect, useParams } from "react-router";
import Config from "utils/Config";

export async function action({params, request }) {

  const data = await request.formData();
  const activateAccountData = {
    password: data.get("password"),
    confirmPassword: data.get("confirmPassword"),
    userHash: params.userHash,
  };
  const actionResponse = {
    style: "",
    message: "",
  };

  if (!correctPasswordData(activateAccountData)) {
    actionResponse.style = "danger";
    actionResponse.message = "Невалидна парола!";
    return actionResponse;
  }

    const response = await fetch(Config.apiServerAddress + "/auth/activate", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(activateAccountData),
    });

    if (!response.ok) {
      actionResponse.style = "danger";
      actionResponse.message = "Неуспешна активация, опитайте отново!";
      return actionResponse;
    }

  actionResponse.style = "success";
  actionResponse.message = 
  "Акаунта ви е успешно активиран! \n\nЩе бъдете пренасочени към страницата за вход....";
  return actionResponse;
}

function correctPasswordData(data) {
  if (data.password !== data.confirmPassword) {
    return false;
  }
  if (data.password === "") {
    return false;
  }

  return true;
}
