import { Skeleton } from "@mui/material";
import React from "react";
import tableStyle from "components/style/DataTable.module.css";

export default function TableLoading(props) {
  const { headerElements, rows } = props;
  var numberOfRows = props.rows === 0 || props.rows === undefined ? 5 : props.rows;

  return (
    <>
      <div className="py-2 table-responsive-xl">
        <table className={tableStyle.table}>
          <thead>
            <tr>
              {headerElements.map((headerElement) => (
                <th key={headerElement}>{headerElement}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Array.from({ length: numberOfRows }).map((_, rowIndex) => (
              <tr key={rowIndex}>
                {headerElements.map((headerElement) => (
                  <td key={headerElement}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
