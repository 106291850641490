const NewBadge = () => {
    return (
      <span className="position-absolute top-0 badge rounded-pill text-bg-success">
        NEW
      </span>
    );
  };

  const HotBadge = () => {
    return (
      <span className="position-absolute top-0 badge rounded-pill text-bg-warning">
        HOT
      </span>
    );
  };

 export { NewBadge, HotBadge };