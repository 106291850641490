import style from  "./style/Loading.module.css";
import { GridLoader, PropagateLoader } from "react-spinners";

const PropagateLoading = () => {
    return (
        <div className="mx-auto">
            <PropagateLoader color="#00ce5d" loading size={30} speedMultiplier={1} />
            {/* <GridLoader color="#74f281" loading size={50} speedMultiplier={1} /> */}
        </div>
    );
}

export default PropagateLoading;