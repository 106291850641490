function isProductDisabled(product) {
    const preferences = product.preferences;
  
    if (!Array.isArray(preferences)) { // Проверка дали preferences е масив
      return false;
    }
  
    const disablePreference = preferences.find(
      (preference) => preference.preference.systemName === "disable_store_product"
    );
    
    return disablePreference !== undefined;
  }

    export { isProductDisabled };