import { redirect } from "react-router";
import Config from "utils/Config";
import { convertToISODate } from "utils/DateFormater";
import { getAuthToken } from "utils/auth";

export async function action({ request }) {
    const token = getAuthToken();
  
    const data = await request.formData();
    const response = {
      code: "",
      message: "",
    };

    console.log("DATA: " + data.get("expireDate"));

    const newProductData = {
      ean: data.get("ean"),
      storeId: data.get("storeId"),
      productId: data.get("productId"),
      itemNumber: data.get("itemNumber"),
      productName: data.get("productName"),
      productVolume: data.get("productVolume"),
      productVendor: data.get("productVendor"),
      categoryId: data.get("categoryId") !== "new" ? data.get("categoryId") : 0,
      newCategory: data.get("newCategory") !== null ? data.get("newCategory") : null,
      supplierId: data.get("supplierId") !== 0 ? data.get("supplierId") : 0,
      expireDate: data.get("expireDate") !== "" ? convertToISODate(data.get("expireDate")) : null,
      warnDays: data.get("warnDays"),
      planogramId: data.get("planogramId"),
    };
  
    console.log(newProductData);
  
    const fetchResponse = await fetch(
      Config.apiServerAddress + "/storeproducts",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newProductData),
      }
    );
  
    if (fetchResponse.status === 422 || fetchResponse.status === 401) {
      response.code = "422";
      response.message = "Нещо се обърка. Опитайте отново!";
      return redirect(
        `/store/${newProductData.storeId}/add-product/planogram/${newProductData.planogramId}?status=not-saved`
      );
    }
  
    if (!fetchResponse.ok) {
      return redirect(
        `/store/${newProductData.storeId}/add-product/planogram/${newProductData.planogramId}?status=not-saved`
      );
    }
  
    return redirect(
      `/store/${newProductData.storeId}/add-product/planogram/${newProductData.planogramId}?status=saved`
    );
  }