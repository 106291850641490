import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router";
import Modal from "./Modal";
import { Html5QrcodeScanner } from "html5-qrcode";
import style from "./style/BarcodeScannerModal.module.css";
import { useMediaQuery } from "@mui/material";

const BarcodeScannerModal = (props) => {
  const [data, setData] = useState("Not Found");
  const navigate = useNavigate();
  const html5QrcodeScannerRef = useRef(null);
  const notMobile = useMediaQuery("(min-width:730px)");
  var scannerWindow = notMobile ? 400 : 200;

  const onScanSuccess = (decodedText, decodedResult) => {
    console.log(`Scan result: ${decodedText}`);
    props.onReturnData(decodedText);
    html5QrcodeScannerRef.current.clear();
    props.onClose();
  };

  const onScanError = (errorMessage) => {
    console.error(errorMessage);
  };

  useEffect(() => {
    // Initialize Html5QrcodeScanner only if it does not exist
    if (!html5QrcodeScannerRef.current) {
      html5QrcodeScannerRef.current = new Html5QrcodeScanner(
        "reader", { fps: 200, qrbox: scannerWindow }, false
      );
    }

    // Render the scanner when the modal opens
    html5QrcodeScannerRef.current.render(onScanSuccess, onScanError);

    return () => {
      // Clear the scanner when the modal closes
      if (html5QrcodeScannerRef.current) {
        html5QrcodeScannerRef.current.clear();
      }
    };
  }, []);

  const onCloseHandler = () => {
    if (html5QrcodeScannerRef.current) {
      html5QrcodeScannerRef.current.clear().then(() => {
        html5QrcodeScannerRef.current.stop();
      });
    }
    props.onClose();
  };

  return (
    <Modal>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLongTitle">
            Сканиране на баркод
          </h4>
        </div>
        <div className="modal-body">
        <hr className={style.redLine}/>
          <div className={style.scannerBody} id="reader"></div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-danger ms-1"
            data-dismiss="modal"
            onClick={onCloseHandler}
          >
            Отказ
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default BarcodeScannerModal;
