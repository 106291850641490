import { TopPageNav } from "components/TopPageNavigation";
import Navbar from "layout/Navbar";
import PageContent from "layout/PageContent";
import SideNavigation from "layout/SideNavigation";
import style from "./RootLayout.module.css";
import { Link, redirect, useParams, useRouteError } from "react-router-dom";
import Alert from "@mui/material/Alert";
import { useState } from "react";

export default function ErrorPage() {
  const error = useRouteError();
  const { storeId } = useParams();

  let title = "Нещо се обърка!";
  let message;
  const [endCheckMessage, setEndCheckMessage] = useState(null);

  const endCheckHandler = () => {
    localStorage.removeItem("openProduct");
    localStorage.removeItem("currentCheck");

    localStorage.setItem("checkStatus", "system-abort");
    return redirect(`/store/${storeId}/check-dates`);
  }

  if (error) {
    const isUserInCheck = localStorage.getItem("currentCheck") !== null;
    console.log(isUserInCheck);

    if (isUserInCheck) {

      return (
        <div className={style.rootLayout}>
          <Navbar />
          <div style={{ display: "flex", flexGrow: 1 }}>
            <SideNavigation />
            <main style={{ marginLeft: "20px", flexGrow: 1 }}>
              <PageContent pageTitle={title}>
                <TopPageNav />
                <div className="d-flex align-items-center justify-content-center">
                  <div className="text-center">
                    <Alert severity="error">
                      Тази грешка възникна по време на активна проверка на
                      артикули. <br />
                      С цел избягване на грешка проверката е прекратена. Всички
                      въведени артикули до момента са запазени.
                      <br />
                      Ако желаете да продължите с проверката моля стартирайте я
                      отново!<br /><br />
                      <p className="text-center">
                        <Link
                          className="btn btn-danger ms-3"
                          onClick={endCheckHandler}
                          to={`/store/${storeId}/check-dates`}
                        >
                          Прекрати проверката
                        </Link>
                      </p>
                    </Alert>
                  </div>
                </div>
              </PageContent>
            </main>
          </div>
        </div>
      );
    }

    // @ts-ignore
    if (error.status === 400) {
      message = (
        <div className="d-flex align-items-center justify-content-center">
          <div className="text-center">
            <p className="fs-3">
              {" "}
              <span className="text-danger">Опа!</span> Страницата не е
              намерена.
            </p>
            <p className="lead">Тази страница не съществува.</p>
            <p className="lead">
              <b>
                Ако мислиш че не трябва да виждаш това съобщение, то
                най-вероятно работим по проблема и скоро ще бъде отстранен.
              </b>
            </p>
          </div>
        </div>
      );
    }
  }

  return (
    <div className={style.rootLayout}>
      <Navbar />
      <div style={{ display: "flex", flexGrow: 1 }}>
        <SideNavigation />
        <main style={{ marginLeft: "20px", flexGrow: 1 }}>
          <PageContent pageTitle={title}>
            <TopPageNav />
            {/* {message} */}
            {endCheckMessage}
          </PageContent>
        </main>
      </div>
    </div>
  );
}
