import PageSubtitle from "components/PageSubTitle";
import PageContent from "layout/PageContent";
import React, { useEffect, useState } from "react";
import style from "./style/UserProfile.module.css";
import image from "../../assets/images/dummy-profile.png";
import InfoIcon from "@mui/icons-material/Info";
import { TopPageNav } from "components/TopPageNavigation";
import Alert from "@mui/material/Alert";
import AlertTitle from "@mui/material/AlertTitle";
import { json, Link, useParams } from "react-router-dom";
import useMediaQuery from "@mui/material/useMediaQuery";
import Loading from "components/Loading";
import EditProfileModal from "./EditProfileModal";
import { formatDateStringFromTimestamp } from "utils/DateStyler";
import { getAuthToken } from "utils/auth";
import Config from "utils/Config";
import SnackBarAlert from "components/SnackBarAlert";

export default function UserProfile() {
  const [userLoading, setUserLoading] = useState(true);
  const [employeeLoading, setEmployeeLoading] = useState(true);
  const [user, setUser] = useState(null);
  const [employments, setEmployments] = useState(null);
  const [alert, setAlert] = useState({ severity: "", message: "" });
  const [open, setOpen] = useState(false);
  const [showEditProfileModal, setShowEditProfileModal] = useState(false);
  const [pageTitle, setPageTitle] = useState("Моят профил");
  let userUpdated = localStorage.getItem("userUpdated");
  let { userId } = useParams();

  useEffect(() => {
    if (!userLoading) {
      setUserLoading(true);
    }

    if (userUpdated === "true") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Профила е успешно актуализиран!",
      });
    }

    if (userId === null || userId === undefined) {
      setPageTitle("Моят профил");
    } else {
      setPageTitle("Преглед на профил");
    }

    getUserInfo(userId).then((user) => {
      setUser(user);
      setUserLoading(false);

      getEmployeeInfo(userId).then((employments) => {
        console.log("employments", employments);
        setEmployments(employments);
        setEmployeeLoading(false);
      });
    });

    setTimeout(() => {
      handleAlertClose();
    }, 3000);

    localStorage.removeItem("userUpdated");
  }, [userId, userUpdated]);

  function handleAlertClose() {
    setOpen(false);
    setAlert({ severity: "", message: "" });
  }

  return (
    <PageContent pageTitle={pageTitle}>
      <TopPageNav />
      {showEditProfileModal && (
        <EditProfileModal
          onClose={() => setShowEditProfileModal(false)}
          user={user}
        />
      )}
      <SnackBarAlert open={open} alert={alert} />
      {userLoading && <Loading />}
      {!userLoading && (
        <UserInfo
          editProfileModalHandler={() => setShowEditProfileModal(true)}
          user={user}
          userId={userId}
        />
      )}
      {employeeLoading && !userLoading && <Loading />}
      {!employeeLoading && (
        <>
          <PageSubtitle>Участие в обекти</PageSubtitle>
          <EmployeeInfo employments={employments} />
        </>
      )}
    </PageContent>
  );
}

function UserInfo(props) {
  const user = props.user;
  console.log("user", user);
  return (
    <div className="py-2 mb-5">
      <div className={style.userInfoContainer}>
        <div className={style.userInfoName}>
          {user.firstName + " " + user.lastName}
        </div>
        <div className={style.userInfoDataContainer}>
          <div className={style.userInfoAvatar}>
            <img src={image} />
          </div>
          <div className={style.userInfoDataElements}>
            <UserInfoElement label="Имейл">{user.email}</UserInfoElement>
            <UserInfoElement label="Телефон за връзка">
              {user.mobilePhone}
            </UserInfoElement>
            <UserInfoElement label="Пол">
              {genderDecode(user.genderId)}
            </UserInfoElement>
            <UserInfoElement label="Град">
              {user.city !== null ? user.city.name : "Не е посочен"}
            </UserInfoElement>
            <UserInfoElement label="Код на профила">
              {user.employeeCode}
            </UserInfoElement>
            <UserInfoElement label="Дата на регистрация">
              {formatDateStringFromTimestamp(user.registerDate)}
            </UserInfoElement>
            <UserInfoElement label="Вид акаунт">
              {userRoleDecode(user.role)}
            </UserInfoElement>
            <UserInfoElement label="Статус на профила">
              {user.active ? "Активен" : "Неактивен"}
            </UserInfoElement>
            <UserInfoElement label="Участие в обекти">
              {user.employeeCount > 0 ? user.employeeCount : "Няма"}
            </UserInfoElement>
          </div>
        </div>
        <div className={style.cardFooter}>
          <span className={style.buttonsContainer}>
            {props.userId === undefined && (
              <>
                <DynamicButton
                  to={""}
                  icon="fa-solid fa-user-pen"
                  label="Редактирай"
                  onClick={props.editProfileModalHandler}
                />
                <DynamicButton
                  to={""}
                  icon="fa-solid fa-gear"
                  label="Настройки"
                />
              </>
            )}
            {props.userId !== undefined && (
              <DynamicButton
                to={""}
                icon="fa-solid fa-message"
                label="Съобщениe"
              />
            )}
          </span>
        </div>
      </div>
    </div>
  );
}

function EmployeeInfo(props) {
  if (
    props.employments === null ||
    props.employments === undefined ||
    props.employments.length === 0
  ) {
    return <EmployeeInfoNotFound />;
  } else {
    return (
      <>
        {props.employments?.map((employee, index) => (
          <div className="py-2" key={index}>
            <div className={style.userInfoContainer}>
              <div className={style.employeeInfoStoreName}>
                {employee.storeName}
              </div>
              <div className={style.employeeInfoStoreAddress}>
                {employee.storeAddress}
              </div>
              <div className={style.employeeInfoDataContainer}>
                <div className={style.userInfoDataElements}>
                  <UserInfoElement label="Участие">
                    {employee.role === "IS_OWNER"
                      ? "Администратор"
                      : "Служител"}
                  </UserInfoElement>
                  <UserInfoElement label="Рейтинг">
                    <Rating rating={employee.rating} />
                  </UserInfoElement>
                  <UserInfoElement label="Проверка на 1 артикул">
                    Под {getMinutes(employee.speed)} мин
                  </UserInfoElement>
                  <UserInfoElement label="Общо проверки">
                    {employee.totalChecks}
                  </UserInfoElement>
                </div>
              </div>
              <div className={style.cardFooter}>
                <span className={style.buttonsContainer}>
                  <DynamicButton
                    to={""}
                    icon="fa-solid fa-gear"
                    label="Настройки"
                  />
                </span>
              </div>
            </div>
          </div>
        ))}
      </>
    );
  }
}

const UserInfoElement = (props) => {
  var value = props.children ? props.children : "---";
  return (
    <div className={style.userInfoElement}>
      <p id={style.title}>
        <InfoIcon id={style.icon} />
        {props.label}
      </p>
      <p className={style.value} id={props.id ? props.id : " "}>
        {value}
      </p>
    </div>
  );
};

const EmployeeInfoNotFound = () => {
  return (
    <Alert variant="standard" severity="warning">
      <AlertTitle>
        <b>Внимание</b>
      </AlertTitle>
      <p>Към момента не участвате в никой обект!</p>
      <p>Създайте обект или се присъединете към вече създаден.</p>
    </Alert>
  );
};

const DynamicButton = (props) => {
  const notMobile = useMediaQuery("(min-width:570px)");
  let buttonStyle = notMobile ? style.actionButton : style.mobileActionButton;

  return (
    <Link className={buttonStyle} to={props.to} onClick={props.onClick}>
      <i className={props.icon} /> {notMobile ? props.label : ""}
    </Link>
  );
};

async function getUserInfo(userId) {
  const token = getAuthToken();

  let serverUrl;

  if (userId === null || userId === undefined) {
    serverUrl = Config.apiServerAddress + "/users/profile";
  } else {
    serverUrl = Config.apiServerAddress + `/users/profile?userId=${userId}`;
  }

  const response = await fetch(serverUrl, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw Error("Could not fetch user.");
  } else {
    const user = await response.json();
    return user;
  }
}

async function getEmployeeInfo(userId) {
  const token = getAuthToken();

  let serverUrl;

  if (userId === null || userId === undefined) {
    serverUrl = Config.apiServerAddress + "/users/profile/employments";
  } else {
    serverUrl =
      Config.apiServerAddress + `/users/profile/employments?userId=${userId}`;
  }

  const response = await fetch(serverUrl, {
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw Error("Could not fetch user employments.");
  } else {
    const employments = await response.json();
    return employments;
  }
}

function userRoleDecode(role) {
  switch (role) {
    case "ROLE_USER":
      return "Потребител";
    case "ROLE_ADMIN":
      return "Администратор";
    case "ROLE_MODERATOR":
      return "Модератор";
    case "ROLE_COOPERATOR":
      return "Сътрудник";
    default:
      return "Неразпозната роля";
  }
}

function genderDecode(genderId) {
  switch (genderId) {
    case 1:
      return "Мъж";
    case 2:
      return "Жена";
    case 3:
      return "Друг";
    default:
      return "Не е посочен";
  }
}

const Rating = ({ rating }) => {
  const ratingStars = [];

  for (let i = 0; i < 5; i++) {
    if (i < rating) {
      ratingStars.push(<i className="fa fa-star"></i>);
    } else {
      ratingStars.push(<i className="fa-regular fa-star"></i>);
    }
  }

  return <span className={style.rating}>{ratingStars}</span>;
};

function getMinutes(seconds) {
  const minutes = Math.ceil(seconds / 60);
  return minutes;
}
