import { useMediaQuery } from "@mui/material";
import PageTitle from "../components/PageTitle";
import style from "./style/PageContent.module.css";
import { useProSidebar } from "react-pro-sidebar";
import { useEffect, useState } from "react";
import CookieConsentModal from "./CookieConsentModal";

export default function PageContent(props) {
  const matches = useMediaQuery("(min-width: 550px)");
  var cookieAproved = localStorage.getItem("cookieAproved");
  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const { collapseSidebar } = useProSidebar();
  useEffect(()=>{
    if(!matches){
      collapseSidebar(true);
    }
    if(cookieAproved === null){
      setShowCookieConsent(true);
    }
  },[matches, collapseSidebar])

  function onCloseHandler(){
    setShowCookieConsent(false);
  }

  const innerContainerStyle = "row justify-content-center align-items-center py-1";
  return (
    <div className={style.container}>
      {!cookieAproved && showCookieConsent && <CookieConsentModal onClose={onCloseHandler}/>}
      <PageTitle title={props.pageTitle} info={props.info}/>
      <div
        className={innerContainerStyle}
        style={{ width: "100%" }}
      >
        {props.children}
      </div>
    </div>
  );
}
