import { redirect } from "react-router";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

export async function action({ request }) {
  const data = await request.formData();
  const formData = {
    planogramId: parseInt(data.get("planogramId"), 10),
    planogramName: encodeURIComponent(data.get("planogramName")),
    storeId: parseInt(data.get("storeId"), 10),
    ean: parseInt(data.get("ean"), 10),
  };

  const token = getAuthToken();

  const response = await fetch(
    Config.apiServerAddress +
      `/storeproducts/single?ean=${formData.ean}&storeId=${formData.storeId}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.status === 200) {
    return redirect(
      `/store/${formData.storeId}/add-product/planogram/${formData.planogramId}?name=${formData.planogramName}&status=exist`
    );
  }

  if (formData.categoryId !== null || formData.categoryId !== 0) {
    return redirect(
      `/store/${formData.storeId}/add-product/planogram/${formData.planogramId}/product/${formData.ean}`
    );
  }
}
