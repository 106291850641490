import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

export default function SnackBarAlert(props) {
  const alert = props.alert;
  const [open, setOpen] = useState(props.open);

  useEffect(() => {
    setOpen(props.open);
  }, [props.open]);

  function handleClose() {
    setOpen(false);
  }

  return (
    <Snackbar open={open} autoHideDuration={2500} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        variant="filled"
        // @ts-ignore
        severity={alert.severity || "info"} // default to "info" if severity is not provided
        sx={{
          width: "fit-content",
          position: "fixed",
          bottom: "8%",
          left: "2%",
        }}
      >
        {alert.message}
      </Alert>
    </Snackbar>
  );
}
