import "./App.css";
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import {
  createBrowserRouter,
  RouterProvider,
  useSearchParams,
} from "react-router-dom";
import { Suspense, lazy } from "react";

import RootLayout from "views/Root";
import Dispatch from "utils/Dispatch";
import { AddNewBusiness, BusinessDashboard } from "views/business";
import { isUserLogged, tokenLoader } from "./utils/auth";

import { action as addNewProductAction } from "views/products/actions/AddNewProductAction";
import { action as PlanogramSelectAction } from "views/planograms/actions/PlanogramSelectAction";
import { action as createPlanogramAction } from "views/planograms/actions/CreatePlanogramAction";
import { action as searchEmployeeCodeAction } from "views/employees/actions/SearchEmployeeCodeAction";
import { action as loginAction } from "views/authorize/actions/LoginAction";
import { action as createUserAction } from "views/users/actions/CreateUserAction";
import { action as eanInputAction } from "views/products/actions/EanInputAction";
import { action as forgottenPasswordAction } from "views/authorize/actions/ForgottenPasswordAction";
import { action as confirmAccountAction } from "views/authorize/actions/ConfirmAction";
import { action as checkProductAction } from "views/checkDate/actions/CheckProductAction";
import CheckProduct from "views/checkDate/CheckProduct";
import ErrorPage from "views/ErrorPage";
import Suppliers from "views/suppliers/Suppliers";
import ProductReference from "views/products/ProductReference";
import { ChecksReferences } from "views/checkDate/ChecksReferences";
import { CheckReport } from "views/checkDate/CheckReport";
import UserProfile from "views/userProfile/UserProfile";
import ConfirmModal from "layout/ConfirmModal";
import PdfMock from "components/ProductsCheckDocument";
import ExportPdf from "components/ExportPdf";
import PdfChecks from "views/pdfChecks/PdfChecks";
import PdfCheckInput from "views/pdfChecks/PdfCheckInput";

// const  = lazy(() => import(""));
const CreatePlanogram = lazy(() =>
  import("views/planograms/CreatePlanogramModal")
);
const Dashboard = lazy(() => import("views/dashboard/Dashboard"));
const GetStarted = lazy(() => import("views/dashboard/GetStarted"));
const Planograms = lazy(() => import("views/planograms/Planograms"));
const SearchEmployeeCode = lazy(() =>
  import("views/employees/SearchEmployeeCode")
);
const BeatLoading = lazy(() => import("components/BeatLoading"));
const PlanogramSelect = lazy(() =>
  import("views/planograms/PlanogramSelectModal")
);
const UsersList = lazy(() => import("./views/users/UsersList"));
const BusinessesList = lazy(() => import("./views/business/BusinessesList"));
const CheckDates = lazy(() => import("views/checkDate/CheckDates"));
const Login = lazy(() => import("views/authorize/Login"));
const CreateNewUser = lazy(() => import("views/users/CreateNewUser"));
const EanInput = lazy(() => import("views/products/EanInput"));
const SetEmployee = lazy(() => import("views/employees/SetEmployee"));
const Employees = lazy(() => import("views/employees/Employees"));
const AddProduct = lazy(() => import("views/products/AddProduct"));
const Products = lazy(() => import("views/products/Products"));
const CheckDateProductsList = lazy(() =>
  import("views/checkDate/CheckDateProducts")
);
const ForgottenPassword = lazy(() =>
  import("views/authorize/ForgottenPassword")
);
const Confirm = lazy(() => import("views/authorize/Confirm"));

const ProtectedRoute = ({ children }) => {
  //TODO: Да се довърши
  // const user = useSelector((state) => state.user);
  // let location = useLocation();

  // if (!isUserLogged()) {
  //   return <Navigate to="/login" replace />;
  // }
  return children;
};

const App = () => {
  const router = createBrowserRouter([
    {
      path: "/",
      element: <RootLayout />,
      id: "root",
      loader: tokenLoader,
      errorElement: <ErrorPage />,
      children: [
        // @ts-ignore
        { index: true, element: <Dispatch /> },
        {
          path: "dashboard",
          element: (
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          ),
        },
        {
          path: "getStarted",
          element: (
            <ProtectedRoute>
              <GetStarted />
            </ProtectedRoute>
          ),
        },
        {
          path: "create-business",
          element: (
            <ProtectedRoute>
              <AddNewBusiness />
            </ProtectedRoute>
          ),
        },
        {
          path: "store/:storeId",
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute>
                  <BusinessDashboard />
                </ProtectedRoute>
              ),
            },
            {
              path: "select",
              loader: (meta) =>
                import("./views/business/loaders/SelectBusinessLoader").then(
                  (module) => module.loader(meta)
                ),
            },
            {
              path: "products/reference/:productEan?/:expireDate?",
              element: (
                <ProtectedRoute>
                  <ProductReference />
                </ProtectedRoute>
              ),
            },
            {
              path: "products/:deleteId/delete",
              loader: (meta) =>
                import("./views/products/loaders/DeleteProductLoader").then(
                  (module) => module.loader(meta)
                ),
            },
            {
              path: "products/page/:page/:filter?/:sort?/:order?",
              element: (
                <ProtectedRoute>
                  <Products />
                </ProtectedRoute>
              ),
              loader: (meta) =>
                import("./views/products/loaders/GetProductsLoader").then(
                  (module) => module.loader(meta)
                ),
            },
            {
              path: "check-dates",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<BeatLoading />}>
                    <CheckDates />
                  </Suspense>
                </ProtectedRoute>
              ),
              loader: (meta) =>
                import("./views/checkDate/loaders/CheckDatesLoader").then(
                  (module) => module.loader(meta)
                ),
            },
            {
              path: "pdf-checks",
              element: (
                <ProtectedRoute>
                  <PdfChecks />
                </ProtectedRoute>
              ),
            },
            {
              path: "pdf-checks/:checkNumber",
              element: (
                <ProtectedRoute>
                  <PdfCheckInput />
                </ProtectedRoute>
              ),
            },
            {
              path: "check-dates/check-product/:rand",
              element: (
                <ProtectedRoute>
                  <Suspense fallback={<BeatLoading />}>
                    <CheckProduct />
                  </Suspense>
                </ProtectedRoute>
              ),
              action: checkProductAction,
              loader: (meta) =>
                import("./views/checkDate/loaders/GetCheckProduct").then(
                  (module) => module.loader(meta)
                ),
            },
            {
              path: "check-dates/:date/view",
              element: (
                <ProtectedRoute>
                  <CheckDateProductsList />
                </ProtectedRoute>
              ),
              loader: (meta) =>
                import(
                  "./views/checkDate/loaders/CheckDateProductsListLoader"
                ).then((module) => module.loader(meta)),
            },
            {
              path: "check-dates/:date/start",
              // element: <CheckDateProductsList />,
              loader: (meta) =>
                import("./views/checkDate/loaders/StartCheckLoader").then(
                  (module) => module.loader(meta)
                ),
            },
            {
              path: "employees",
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute>
                      <Employees />
                    </ProtectedRoute>
                  ),
                  loader: (meta) =>
                    import("./views/employees/loaders/EmployeesLoader").then(
                      (module) => module.loader(meta)
                    ),
                },
                // {
                //   path: "add",
                //   element: (
                //     <ProtectedRoute>
                //       <SearchEmployeeCode />
                //     </ProtectedRoute>
                //   ),
                //   action: searchEmployeeCodeAction,
                // },
                {
                  path: "add/:employeeCode",
                  element: (
                    <ProtectedRoute>
                      <SetEmployee />
                    </ProtectedRoute>
                  ),
                  loader: (meta) =>
                    import("./views/employees/loaders/EmployeeLoader").then(
                      (module) => module.loader(meta)
                    ),
                },
              ],
            },
            {
              path: "planograms",
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute>
                      <Planograms />
                    </ProtectedRoute>
                  ),
                  loader: (meta) =>
                    import(
                      "./views/planograms/loaders/PlanogramsInfoLoader"
                    ).then((module) => module.loader(meta)),
                },
                {
                  path: "delete/:deleteId",
                  loader: (meta) =>
                    import(
                      "./views/planograms/loaders/DeletePlanogramLoader"
                    ).then((module) => module.loader(meta)),
                },
                {
                  path: "delete/:deleteId/move/:moveId",
                  loader: (meta) =>
                    import(
                      "./views/planograms/loaders/DeleteMovePlanogramLoader"
                    ).then((module) => module.loader(meta)),
                },
                {
                  path: "create-planogram",
                  action: createPlanogramAction,
                },
              ],
            },
            {
              path: "suppliers",
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute>
                      <Suppliers />
                    </ProtectedRoute>
                  ),
                  loader: (meta) =>
                    import(
                      "./views/suppliers/loaders/SuppliersInfoLoader"
                    ).then((module) => module.loader(meta)),
                },
                // {
                //   path: "delete/:deleteId",
                //   loader: (meta) =>
                //     import(
                //       "./views/planograms/loaders/DeletePlanogramLoader"
                //     ).then((module) => module.loader(meta)),
                // },
                // {
                //   path: "delete/:deleteId/move/:moveId",
                //   loader: (meta) =>
                //     import(
                //       "./views/planograms/loaders/DeleteMovePlanogramLoader"
                //     ).then((module) => module.loader(meta)),
                // },
                // {
                //   path: "create-planogram",
                //   action: createPlanogramAction,
                // },
              ],
            },
            {
              path: "checks/references",
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute>
                      <ChecksReferences />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: "page/:page",
                  element: (
                    <ProtectedRoute>
                      <ChecksReferences />
                    </ProtectedRoute>
                  ),
                },
                {
                  path: ":checkId?",
                  element: (
                    <ProtectedRoute>
                      <CheckReport />
                    </ProtectedRoute>
                  ),
                },
              ],
            },
            {
              path: "ean-checker",
              children: [
                {
                  index: true,
                  element: (
                    <ProtectedRoute>
                      <PlanogramSelect />
                    </ProtectedRoute>
                  ),
                  action: PlanogramSelectAction,
                  loader: (meta) =>
                    import(
                      "./views/planograms/loaders/PlanogramsListLoader"
                    ).then((module) => module.loader(meta)),
                },
                {
                  path: ":saved",
                  element: (
                    <ProtectedRoute>
                      <Suspense fallback={<BeatLoading />}>
                        <PlanogramSelect />
                      </Suspense>
                    </ProtectedRoute>
                  ),
                  action: PlanogramSelectAction,
                },
              ],
            },
            {
              path: "add-product/planogram/:planogramId",
              element: (
                <ProtectedRoute>
                  <EanInput />
                </ProtectedRoute>
              ),
              action: eanInputAction,
            },
            {
              path: "add-product/planogram/:planogramId/:saved",
              element: (
                <ProtectedRoute>
                  <EanInput />
                </ProtectedRoute>
              ),
              action: eanInputAction,
            },
            {
              path: "add-product/planogram/:planogramId/product/:ean",
              element: (
                <ProtectedRoute>
                  <AddProduct />
                </ProtectedRoute>
              ),
              loader: (meta) =>
                import("views/products/loaders/GetProductToAddLoader").then(
                  (module) => module.loader(meta)
                ),
              action: addNewProductAction,
            },
          ],
        },
        {
          path: "users",
          children: [
            {
              index: true,
              element: (
                <ProtectedRoute>
                  <UsersList />
                </ProtectedRoute>
              ),
              loader: () =>
                import("./views/users/UsersList").then((module) =>
                  module.loader()
                ),
            },
            {
              path: "create",
              element: (
                <ProtectedRoute>
                  <CreateNewUser />
                </ProtectedRoute>
              ),
              action: createUserAction,
            },
          ],
        },
        {
          path: "stores",
          element: (
            <ProtectedRoute>
              <BusinessesList />
            </ProtectedRoute>
          ),
          loader: () =>
            import("./views/business/loaders/BusinessListLoader").then(
              (module) => module.loader()
            ),
        },
        {
          path: "profile/:userId?",
          element: (
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          ),
        },
        {
          path: "under-construction",
          element: (
            <ProtectedRoute>
              <ConfirmModal />
            </ProtectedRoute>
          ),
        },
        {
          path: "logout",
          loader: () =>
            import("./views/authorize/Logout").then((module) =>
              module.loader()
            ),
        },
      ],
    },
    {
      path: "login",
      element: <Login />,
      action: loginAction,
    },
    {
      path: "forgotten-password",
      element: <ForgottenPassword />,
      action: forgottenPasswordAction,
    },
    {
      path: "confirm/:userHash",
      element: <Confirm />,
      loader: (userHash) =>
        import("./views/authorize/loaders/ConfirmLoader").then((module) =>
          module.loader(userHash)
        ),
      action: confirmAccountAction,
    },
    {
      path: "export-pdf",
      element: <ExportPdf />,
    }
  ]);

  return <RouterProvider router={router} />;
};

export default App;
