import React from "react";

import style from "./style/Footer.module.css";
import { Link, NavLink } from "react-router-dom";
import { useMediaQuery } from "@mui/material";

export default function Footer() {
  const notMobile = useMediaQuery("(min-width:730px)");

  if (notMobile) {
    return (
      <div className={style.footerContainer}>
        <div className={style.copyRight}>&copy; 2023-2024 Emil Nikolov</div>
        <div className={style.centerLogo}>ExpireMate</div>
        <div className={style.icons}>
          <Icons />
        </div>
      </div>
    );
  } else {
    return (
      <div className={style.footerContainer}>
        <div className={style.centerLogo}>ExpireMate</div>
        <div className={style.icons}>
          <Icons />
        </div>
        <div className={style.copyRight}>&copy; 2023-2024 Emil Nikolov</div>
      </div>
    );
  }
}

const Icons = () => {
  return (
    <div className={style.iconsContainer}>
      <NavLink className={style.icon} id={style.linkedin} to={"https://linkedin.com/in/emil-nikolov92"}>
        <i className="fa-brands fa-linkedin"></i>
      </NavLink>
      <Link className={style.icon} id={style.facebook} to={"https://facebook.com/people/Emil-Nikolov-Alpineca"}>
        <i className="fa-brands fa-facebook"></i>
      </Link>
      <Link className={style.icon} id={style.gmail} to={""}>
        <i className="fa-solid fa-envelope"></i>
      </Link>
      <Link className={style.icon} id={style.github} to={"https://github.com/alpineca"}>
        <i className="fa-brands fa-github"></i>
      </Link>
    </div>
  );
};
