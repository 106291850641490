import { useEffect } from "react";

import "./style/UnderConstruction.css";
import image from "../assets/images/under_construction.jpg";
import { useNavigate } from "react-router-dom";
import { getAuthToken } from "utils/auth";

const UnderConstruction = () => {
  const navigate = useNavigate();
  const token = getAuthToken();

  useEffect(() => {
    if (!token || token === null || token === "EXPIRED") {
      return navigate("/login");
    }
  }, [token, navigate]);

  const goBack = () => {
    // redirect(-1);
    console.log("back");
  };

  return (
    <div className="uc--container">
      <div className="uc--image-container">
        <img className="uc--image" src={image} alt="Under construction"/>
      </div>
      <button className="btn btn-success ms-1" onClick={goBack}>
        Go Back
      </button>
    </div>
  );
};

export default UnderConstruction;
