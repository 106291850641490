import React, { useEffect, useState } from "react";
import Modal from "components/Modal";
import { Form, redirect } from "react-router-dom";
import formStyle from "components/style/Form.module.css";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";
import DataLoading from "components/DataLoading";
import DataUpdating from "components/DataUpdating";

export default function EditProfileModal(props) {
  const [user, setUser] = useState(props.user);
  const oldUser = props.user;
  const [cities, setCities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    loadCities().then((cities) => {
      setCities(cities);
      setLoading(false);
    });
  }, []);

  const editUserHandler = async (event) => {
    event.preventDefault();
    setSaving(true);
    const formData = new FormData(event.target);

    const updatedUserData = {
      id: formData.get("userId"),
      firstName: formData.get("firstName"),
      lastName: formData.get("lastName"),
      mobilePhone: formData.get("mobilePhone"),
      genderId: formData.get("genderId"),
      cityId: formData.get("cityId"),
    };

    if (
      updatedUserData.firstName === "" ||
      updatedUserData.lastName === "" ||
      updatedUserData.mobilePhone === ""
    ) {
      setError("Моля попълнете всички задължителни полета!");
      setSaving(false);
      return;
    }
    try {
      await updateUser(updatedUserData);
    } catch (error) {
      setError("Възникна грешка при обновяването на данните!");
      setSaving(false);
      return;
    }

    setSaving(false);
    localStorage.setItem("userUpdated", "true");
    props.onClose();
    redirect("/profile");
  };

  if (loading) {
    return (
      <Modal>
        <DataLoading />
      </Modal>
    );
  }
  if (saving) {
    return (
      <Modal>
        <DataUpdating />
      </Modal>
    );
  } else {
    return (
      <Modal>
        <form
          method="post"
          onSubmit={editUserHandler}
        >
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title" id="exampleModalLongTitle">
                Редактиране на профила
              </h4>
            </div>
            <div className={"modal-body my-3 me-3 " + formStyle.formContainerClear}>
              {error && <div className="alert alert-danger">{error}</div>}
              <label htmlFor="firstName" className="form-label">
                Първо име
              </label>
              <input
                type={"text"}
                className="form-control mb-3"
                name="firstName"
                value={user.firstName}
                onChange={(e) =>
                  setUser({ ...user, firstName: e.target.value })
                }
                placeholder="Въведете първо име"
                required
              />
              <input type="hidden" name="userId" value={user.id} />
              <label htmlFor="lastName" className="form-label">
                Фамилия
              </label>
              <input
                type={"text"}
                className="form-control mb-3"
                name="lastName"
                value={user.lastName}
                onChange={(e) => setUser({ ...user, lastName: e.target.value })}
                placeholder="Въведете фамилия"
                required
              />
              <label htmlFor="genderId" className="form-label">
                Пол
              </label>
              <select
                className="form-control mb-3"
                name="genderId"
                value={user.genderId}
                onChange={(e) => setUser({ ...user, genderId: e.target.value })}
              >
                <option value="0" selected>
                  Изберете пол
                </option>
                <option value="1" selected={user.genderId === 1}>
                  Мъж
                </option>
                <option value="2" selected={user.genderId === 2}>
                  Жена
                </option>
                <option value="3" selected={user.genderId === 3}>
                  Друг
                </option>
              </select>
              <label htmlFor="mobilePhone" className="form-label">
                Телефон
              </label>
              <input
                type={"text"}
                className="form-control mb-3"
                name="mobilePhone"
                value={user.mobilePhone}
                onChange={(e) =>
                  setUser({ ...user, mobilePhone: e.target.value })
                }
                placeholder="Въведете мобилен телефон"
                required
              />
              <label htmlFor="mobilePhone" className="form-label">
                Град
              </label>
              <select
                className="select form-control"
                name="cityId"
                onChange={(e) => setUser({ ...user, cityId: e.target.value })}
              >
                <option value="0" selected={user.city === null}>
                  Изберете град
                </option>
                {cities.map((city, index) => (
                  <option
                    key={index}
                    value={city.id}
                    selected={user.city !== null && city.id === user.city?.id}
                  >
                    {city.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-outline-secondary ms-1"
                data-dismiss="modal"
                onClick={props.onClose}
              >
                Откажи
              </button>
              <button
                type="submit"
                className="btn btn-success ms-1"
                disabled={oldUser === user}
              >
                Запази промените
              </button>
            </div>
          </div>
        </form>
      </Modal>
    );
  }
}

const loadCities = async () => {
  const token = getAuthToken();

  const request = await fetch(Config.apiServerAddress + "/cities", {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    },
  });

  const result = await request.json();
  return result;
};

async function updateUser(updatedUserData) {
  const token = getAuthToken();

    const request = await fetch(Config.apiServerAddress + "/users", {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(updatedUserData),
    });

    if (!request.ok) {
      throw Error("Could not update user.");
    }

    return true;
}
