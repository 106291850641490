// @ts-ignore
import { Outlet } from "react-router-dom";

// import SideNavigation from React.lazy("layout/SideNavigation");

import Navbar from "layout/Navbar";
import style from "./RootLayout.module.css";
import { lazy, useEffect, useRef, useState } from "react";
import SideNavigation from "layout/SideNavigation";
import Footer from "layout/Footer.js";

function RootLayout() {
  // const [showBusinessMenu, setShowBusinessMenu] = useState(false);
  // const selectedBusiness = JSON.parse(localStorage.getItem("selectedBusiness"));

  // useEffect(()=>{
  //   if(selectedBusiness?.name !== undefined){
  //     setShowBusinessMenu(prevState => true);
  //     console.log("show business menu")
  //   }
  // },[selectedBusiness])


  return (
    <div className={style.rootLayout}>
      <Navbar />
      <div style={{ display: "flex", flexGrow: 1 }}>
        <SideNavigation />
        <main style={{ marginLeft: "20px", flexGrow: 1 }}>
          <Outlet />
        </main>
      </div>
        <Footer />
    </div>
  );
}

export default RootLayout;
