import Alert from "@mui/material/Alert";
import Loading from "components/Loading";
import PageContent from "layout/PageContent";
import { Suspense, useEffect, useRef, useState } from "react";
import { styled } from "@mui/material/styles";
import { Await, useLoaderData, useNavigate, useParams } from "react-router";
import { Form } from "react-router-dom";
import style from "./style/CheckProduct.module.css";
import { checkForLoadedPermission, getAuthToken } from "utils/auth";
import Config from "utils/Config";

import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { formatDate } from "utils/DateFormater";
import { dateEasyRead } from "utils/DateStyler";
import PermissionName from "utils/enums/PermissionName";
import { getSuppliers } from "views/suppliers/loaders/SuppliersInfoLoader";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 15,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));

export default function CheckProduct() {
  // TODO: Когато има артикули със търсения срок на годност не се предлага възможност за въвеждане на срок на годност
  // @ts-ignore
  const { product } = useLoaderData();
  const { storeId } = useParams();
  const { rand } = useParams();
  const navigate = useNavigate();
  let checkData = localStorage.getItem("currentCheck");

  const [showPrimaryInfo, setShowPrimaryInfo] = useState(false);
  const [showSecondaryInfo, setShowSecondaryInfo] = useState(false);
  const [showExpireFields, setShowExpireFields] = useState(true);
  const [expiredProductsFound, setExpiredProductsFound] = useState(false);

  const [info, setInfo] = useState({ color: "", message: "" });
  const [disabledProduct, setDisabledProduct] = useState(false);
  const [showInfo, setShowInfo] = useState(false);

  const [suppliersList, setSuppliersList] = useState([]);

  const [showExpireFoundCheckbox, setShowExpireFoundCheckbox] = useState(true);
  const [showNoQuantityCheckbox, setShowNoQuantityCheckbox] = useState(true);
  const [primaryInfo, setPrimaryInfo] = useState({ color: "", message: "" });
  const [secondaryInfo, setSecondaryInfo] = useState({
    color: "",
    message: "",
  });
  useEffect(() => {
    setShowPrimaryInfo(false);
    setShowSecondaryInfo(false);
    setShowExpireFields(true);
    setExpiredProductsFound(false);
    setShowInfo(false);
    setDisabledProduct(false);

    setShowExpireFoundCheckbox(true);
    setShowNoQuantityCheckbox(true);
    fetchSuppliersList();
  }, [rand]);

  const disabledProductPermission = checkForLoadedPermission(
    PermissionName.DISABLE_PRODUCT
  );

  const expiredFoundHandler = (expireDate, e) => {
    let isChecked = e.target.checked;

    const currentDate = new Date();
    const expirationDate = new Date(expireDate);

    const timeDifference = expirationDate.getTime() - currentDate.getTime();
    const daysDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    if (daysDifference <= 1 && isChecked) {
      setPrimaryInfo({
        color: "error",
        message: `Извади от продажба всички бройки от този артикул със срок на годност ${expireDate}!`,
      });
      setExpiredProductsFound(true);
      setShowPrimaryInfo(isChecked);
    } else if (daysDifference > 1 && isChecked) {
      setShowExpireFields(false);
      setShowNoQuantityCheckbox(false);

      setPrimaryInfo({
        color: "warning",
        message: "Увери се че всички бройки с този срок са най-отпред!",
      });
      setSecondaryInfo({
        color: "info",
        message:
          "При следващата проверка на артикула ще въведете нов най-лош срок на годност.",
      });

      setShowPrimaryInfo(isChecked);
      setShowSecondaryInfo(isChecked);
    }

    if (!isChecked) {
      setShowExpireFields(true);
      setShowNoQuantityCheckbox(true);
      setShowPrimaryInfo(false);
      setShowSecondaryInfo(false);
    }
  };

  const noQuantityHandler = (e) => {
    let isChecked = e.target.checked;

    if (expiredProductsFound && isChecked) {
      setSecondaryInfo({
        color: "success",
        message: "Артикулът ще бъде показан за проверка следващият път!",
      });

      setShowExpireFields(!isChecked);
      setShowSecondaryInfo(isChecked);
    } else if (!expiredProductsFound && isChecked) {
      setShowExpireFoundCheckbox(false);
      setShowExpireFields(!isChecked);

      setPrimaryInfo({
        color: "success",
        message: "Артикулът ще бъде показан при следващата ви проверка!",
      });

      setShowPrimaryInfo(isChecked);
    }

    if (!isChecked && expiredProductsFound) {
      setShowExpireFields(true);
      setShowNoQuantityCheckbox(true);
      setShowSecondaryInfo(false);
    }

    if (!isChecked && !expiredProductsFound) {
      setShowExpireFields(true);
      setShowNoQuantityCheckbox(true);
      setShowExpireFoundCheckbox(true);
      setShowPrimaryInfo(false);
      setShowSecondaryInfo(false);
    }
  };

  async function handleAbort() {
    try {
      await abortCheck(checkData);
    } catch (error) {
      throw new Error("Abort check failed");
    }

    navigate(`/store/${storeId}/check-dates`);
  }

  const ProgressBar = () => {
    const checkData = JSON.parse(localStorage.getItem("currentCheck"));

    let currentProduct = checkData.checkedProductsCount;
    let allProducts = checkData.totalProductsCount;
    let progress = calculateProgress(currentProduct, allProducts);
    // let time =

    // TODO: Да се визуализира оставащо време необходимо за проверка на артикулите
    return (
      <div className={style.progressBarContainer}>
        <BorderLinearProgress variant="determinate" value={progress} />
        <p className={style.progressInfo}>
          Прогрес {progress}% ({currentProduct + 1}/{allProducts})
        </p>
      </div>
    );
  };

  const disableProductHandler = () => {
    if (showInfo) {
      setDisabledProduct(false);
      setShowInfo(false);
      return;
    }

    setInfo({
      color: "error",
      message:
        "<b>Артикула ще бъде деактивиран!</b> <br><br> Артикула няма да бъде показван за проверка докато не въведете отново срок на годност!",
    });
    setDisabledProduct(true);
    setShowInfo(true);
  };

  const expiredFoundCheckBoxRef = useRef(null);
  const noQuantityCheckBoxRef = useRef(null);
  const disableProductCheckBoxRef = useRef(null);

  const fetchSuppliersList = async () => {
    const suppliersList = await getSuppliers(storeId);
    console.log(suppliersList);
    setSuppliersList(suppliersList);
  };

  const SupplierSelect = ({ product }) => {
    if (product.supplier === undefined || product.supplier === null) {
      return (
        <div className={style.dataNeeded}>
          <label htmlFor="supplier" className="form-label">
            Моля изберете доставчик!
          </label>
          <select className="form-control mb-3" name="supplierId">
            <option value="0" selected>
              Изберете доставчик
            </option>
            {suppliersList?.map((supplier, index) => (
              <option key={index} value={supplier.id}>
                {supplier.name}
              </option>
            ))}
          </select>
        </div>
      );
    } else {
      return (
        <input type="hidden" name="supplierId" value={product.supplier.id} />
      );
    }
  };

  return (
    <PageContent pageTitle="Проверка на артикул">
      <Suspense key={rand} fallback={<Loading />}>
        <Form method="POST" className={style.formContainer}>
          <ProgressBar />
          <Await resolve={product}>
            {(product) => (
              <div className="modal-body ms-3">
                {product && (
                  <>
                    <div className={style.productInfoContainer}>
                      <span className={style.vendor}>
                        {product.product.productInfo.vendor}
                      </span>
                      <span className={style.productName}>
                        {product.product.productInfo.name}
                      </span>
                      <span className={style.productInfoElement}>
                        <i
                          className="fa-sharp fa-solid fa-barcode"
                          id={style.icon}
                        ></i>
                        <b> {product.product.ean}</b>
                      </span>
                      <span className={style.productInfoElement}>
                        <i
                          className="fa-solid fa-boxes-packing"
                          id={style.icon}
                        ></i>{" "}
                        <b>{product.product.productInfo.volume}</b>
                      </span>
                      <span className={style.productInfoElement}>
                        <i
                          className="fa-solid fa-table-cells-large"
                          id={style.icon}
                        ></i>{" "}
                        <b>{product.planogram.name}</b>
                      </span>
                      {product.supplier !== null && (
                        <span className={style.productInfoElement}>
                          <i className="fa-solid fa-truck" id={style.icon}></i>
                          <b>{product.supplier.name}</b>
                        </span>
                      )}
                      {product.expire !== null && (
                        <span className={style.expireElement}>
                          Срок на годност:{" "}
                          <h3>{dateEasyRead(product.expire.expireDate)}</h3>
                        </span>
                      )}
                      {product.expire === null && (
                        <span
                          className={style.expireElement}
                          id={style.noQuantity}
                        >
                          <i>Няма наличност от артикула!</i>
                        </span>
                      )}
                    </div>
                  </>
                )}
                <hr />
                <input type="hidden" name="storeId" value={storeId} />
                <input type="hidden" name="productId" value={product?.id} />
                {product.expire === null && disabledProductPermission && (
                  <div className="mb-2">
                    <label
                      className={
                        disableProductCheckBoxRef.current !== null &&
                        disableProductCheckBoxRef.current.checked
                          ? style.checkedCheckBoxContainer
                          : style.checkBoxContainer
                      }
                    >
                      <input
                        type="checkbox"
                        name="disableProduct"
                        className={style.checkBox}
                        onChange={() => disableProductHandler()}
                        ref={disableProductCheckBoxRef}
                      />
                      Деактивирай артикула
                    </label>
                  </div>
                )}
                {showInfo && info && (
                  <Alert
                    variant="filled"
                    // @ts-ignore
                    severity={info.color}
                  >
                    <div dangerouslySetInnerHTML={{ __html: info.message }} />
                  </Alert>
                )}
                {product.expire !== null && (
                  <input
                    type="hidden"
                    name="currentExpireDate"
                    value={product.expire.expireDate}
                  />
                )}
                {!disabledProduct &&
                  showExpireFoundCheckbox &&
                  product.expire !== null && (
                    <div>
                      <label
                        className={
                          expiredFoundCheckBoxRef.current !== null &&
                          expiredFoundCheckBoxRef.current.checked
                            ? style.checkedCheckBoxContainer
                            : style.checkBoxContainer
                        }
                      >
                        <input
                          type="checkbox"
                          name="expiredFound"
                          className={style.checkBox}
                          onClick={(e) =>
                            expiredFoundHandler(product?.expire.expireDate, e)
                          }
                          ref={expiredFoundCheckBoxRef}
                        />
                        СГ <b>{dateEasyRead(product.expire.expireDate)}</b> е
                        наличен?
                      </label>
                    </div>
                  )}
                {!disabledProduct && showNoQuantityCheckbox && (
                  <div>
                    <label
                      className={
                        noQuantityCheckBoxRef.current !== null &&
                        noQuantityCheckBoxRef.current.checked
                          ? style.checkedCheckBoxContainer
                          : style.checkBoxContainer
                      }
                    >
                      <input
                        type="checkbox"
                        name="noQuantity"
                        className={style.checkBox}
                        onClick={(e) => noQuantityHandler(e)}
                        ref={noQuantityCheckBoxRef}
                      />
                      Няма наличност от артикула?
                    </label>
                  </div>
                )}
                {showPrimaryInfo && (
                  <div className={style.alertContainer}>
                    <Alert
                      variant="filled"
                      // @ts-ignore
                      severity={primaryInfo.color}
                    >
                      {primaryInfo.message}
                    </Alert>
                  </div>
                )}
                {showSecondaryInfo && (
                  <div className={style.alertContainer}>
                    <Alert
                      // variant="filled"
                      // @ts-ignore
                      severity={secondaryInfo.color}
                    >
                      {secondaryInfo.message}
                    </Alert>
                  </div>
                )}
                {!disabledProduct && showExpireFields && (
                  <>
                    <label htmlFor="Expire-date" className="form-label">
                      Най-лош срок на годност:
                    </label>
                    <input
                      // type="text"
                      type="date"
                      className="form-control mb-3"
                      // placeholder="ДДММ"
                      name="expireDate"
                      defaultValue={product?.expire?.expireDate}
                      // onBlur={(e) => formatDate(e.target)}
                      required
                    />
                    <label htmlFor="warningdays" className="form-label">
                      Дни предизвестие:
                    </label>
                    <input
                      type="number"
                      className="form-control mb-3"
                      defaultValue={product?.warningDays}
                      name="warnDays"
                      required
                    />
                  </>
                )}
                <SupplierSelect product={product} />
              </div>
            )}
          </Await>
          <div className={style.formButtonsContainer}>
            <button
              type="button"
              className="btn btn-outline-danger ms-1"
              data-dismiss="modal"
              onClick={() => handleAbort()}
            >
              Прекъсни проверка
            </button>
            <button type="submit" className="btn btn-success ms-3">
              Въведи
            </button>
          </div>
        </Form>
      </Suspense>
    </PageContent>
  );
}

async function abortCheck(checkData) {
  const token = getAuthToken();
  const parsedCheckData = JSON.parse(checkData);

  // checkData.endTimestamp = new Date().toISOString();
  parsedCheckData.status = "abort";

  console.log(JSON.stringify(parsedCheckData));

  const fetchResponse = await fetch(
    Config.apiServerAddress + "/expires/currentCheck?action=abort",
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(parsedCheckData),
    }
  );

  if (!fetchResponse.ok) {
    throw new Error("Update faileDDD" + fetchResponse.body);
  } else {
    localStorage.removeItem("currentCheck");
    localStorage.removeItem("openProduct");
    localStorage.setItem("checkStatus", "user-abort");
  }
}

function calculateProgress(completedChecks, totalChecks) {
  // Проверка за валидни входни стойности
  if (
    typeof completedChecks !== "number" ||
    typeof totalChecks !== "number" ||
    totalChecks === 0
  ) {
    throw new Error("Невалидни входни данни.");
  }

  // Изчисляване на процента завършеност
  const percentage = (completedChecks / totalChecks) * 100;

  // Връщане на резултат, закръглен до цяло число
  return Math.round(percentage);
}
