// @ts-ignore
import React, { Fragment } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

// @ts-ignore
import RobotoBlack from "../assets/fonts/Roboto/Roboto-Black.ttf";
// @ts-ignore
import RobotoBlackItalic from "../assets/fonts/Roboto/Roboto-BlackItalic.ttf";
// @ts-ignore
import RobotoBold from "../assets/fonts/Roboto/Roboto-Bold.ttf";
// @ts-ignore
import RobotoBoldItalic from "../assets/fonts/Roboto/Roboto-BoldItalic.ttf";
// @ts-ignore
import RobotoItalic from "../assets/fonts/Roboto/Roboto-Italic.ttf";
// @ts-ignore
import RobotoLight from "../assets/fonts/Roboto/Roboto-Light.ttf";
// @ts-ignore
import RobotoLightItalic from "../assets/fonts/Roboto/Roboto-LightItalic.ttf";
// @ts-ignore
import RobotoMedium from "../assets/fonts/Roboto/Roboto-Medium.ttf";
// @ts-ignore
import RobotoMediumItalic from "../assets/fonts/Roboto/Roboto-MediumItalic.ttf";
// @ts-ignore
import RobotoRegular from "../assets/fonts/Roboto/Roboto-Regular.ttf";
// @ts-ignore
import RobotoThin from "../assets/fonts/Roboto/Roboto-Thin.ttf";
// @ts-ignore
import RobotoThinItalic from "../assets/fonts/Roboto/Roboto-ThinItalic.ttf";
// @ts-ignore
import Montserrat from "../assets/fonts/Montserrat/Montserrat-Bold.ttf";
import { dateEasyRead } from "utils/DateStyler";

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBlack, fontWeight: 900 },
    { src: RobotoBlackItalic, fontWeight: 900, fontStyle: "italic" },
    { src: RobotoBoldItalic, fontWeight: 700, fontStyle: "italic" },
    { src: RobotoItalic, fontWeight: 400, fontStyle: "italic" },
    { src: RobotoLightItalic, fontWeight: 300, fontStyle: "italic" },
    { src: RobotoMedium, fontWeight: 500 },
    { src: RobotoMediumItalic, fontWeight: 500, fontStyle: "italic" },
    { src: RobotoThin, fontWeight: 100 },
    { src: RobotoThinItalic, fontWeight: 100, fontStyle: "italic" },
    { src: RobotoLight, fontWeight: 300 },
    { src: RobotoBold, fontWeight: 700 },
  ],
  format: "truetype",
});
Font.register({
  family: "Montserrat",
  fonts: [{ src: Montserrat, fontWeight: 600 }],
  format: "truetype",
});

const ProductsCheckDocument = (props) => {

  const documentData = props.documentData;

  const styles = StyleSheet.create({
    page: {
      fontSize: 11,
      paddingTop: 40,
      paddingBottom: 45,
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: 1.5,
      flexDirection: "column",
      fontFamily: "Roboto",
      //   fontWeight: 300,
    },

    spaceBetween: {
      flex: 1,
      flexDirection: "row",
      //   alignItems: "start",
      paddingTop: 0,
      justifyContent: "space-between",
      color: "#3E3E3E",
      //   borderWidth: 1,
    },

    titleContainer: {
      flexDirection: "row",
      marginTop: 0,
      justifyContent: "flex-start",
    },

    documentTitleContainer: {
      flexDirection: "row",
      justifyContent: "space-between",
      marginBottom: 0,
      fontSize: 16,
    },

    documentTitle: {
      fontSize: 14,
      textAlign: "left",
      fontWeight: 500,
      marginBottom: 0,
      //   borderWidth: 1,
    },

    documentExecutionDate: {
      fontSize: 11,
      textAlign: "right",
      fontWeight: 400,
      marginTop: 2,
    },

    logo: {
      fontSize: 60,
      fontWeight: 600,
      //   fontStyle: "light",
      paddingTop: -10,
      //   marginTop: 10,
      color: "#a3a3a3",
      marginBottom: -10,
      fontFamily: "Montserrat",
      //   borderWidth: 1,
    },

    infoContainer: {
      flexDirection: "column",
      alignItems: "flex-end",
      justifyContent: "flex-end",
      textAlign: "right",
      color: "#3E3E3E",
      padding: 40,
      paddingRight: 20,
      paddingTop: 10,
      paddingBottom: 10,
      marginBottom: 20,
      borderWidth: 1,
      //   borderRightWidth: 1,
      borderRadius: 10,
      borderColor: "#a3a3a3",
    },

    slogan: {
      fontSize: 10,
      fontWeight: 400,
      fontStyle: "italic",
      marginTop: -20,
      marginLeft: 5,
    },

    reportTitle: { fontSize: 16, textAlign: "center" },

    addressTitle: { fontSize: 11 },

    information: { fontSize: 18, fontWeight: 700, marginBottom: 0 },

    checkInfoElement: {
      fontSize: 10,
      paddingTop: 3,
    },

    checkNumber: {
      fontSize: 20,
      fontWeight: 600, // използвай 'bold' или 700 за удебеляване
      // color: "#3E3E3E",
      fontFamily: "Montserrat",
    },

    underlineInfo: {
      fontSize: 10,
      paddingTop: -5,
      fontWeight: 300,
      fontStyle: "italic",
    },

    // invoiceNum: { fontSize: 15 },

    address: { fontSize: 10 },

    theader: {
      //   marginTop: 20,
      fontSize: 9,
      //   fontStyle: "bold",
      paddingTop: 4,
      paddingLeft: 7,
      paddingRight: 7,
      flex: 1,
      flexWrap: "wrap",
      height: 20,
      backgroundColor: "#DEDEDE",
      borderColor: "grey",
      borderRightWidth: 1,
      borderBottomWidth: 1,
      borderTopWidth: 1,
    },
    tbody: {
      fontSize: 9,
      paddingTop: 4,
      paddingLeft: 7,
      paddingRight: 7,
      flex: 1,
      borderColor: "#a3a3a3",
      borderRightWidth: 1,
      borderBottomWidth: 1,
    },

    theader3: { flex: 3, borderRightWidth: 0, borderBottomWidth: 1 },

    tableContainer: {
      flexDirection: "row",
      flexWrap: "wrap",
      marginTop: 0,
    },

    index: { flex: 0.2, paddingRight: 5, fontWeight: 700 },

    productInfo: { flex: 4, borderRightWidth: 1 },

    ean: { flex: 1.5, borderRightWidth: 1 },

    planogram: { flex: 2, borderRightWidth: 1 },

    expireDate: { flex: 1, borderRightWidth: 1, textAlign: "center" },

    emptyExpireDate: { flex: 1, borderRightWidth: 1, textAlign: "center" },

    emptySpace: {
      flex: 1,
      borderRightWidth: 1,
      paddingRight: 5,
      flexWrap: "wrap",
      textAlign: "center",
    },

    zebraWhite: { backgroundColor: "white" },
    zebraGrey: { backgroundColor: "#eae3e1" },

    bold: { fontWeight: 600 },

    pageCounter: {
      position: "absolute",
      fontSize: 10,
      bottom: 10,
      marginTop: 20,
      left: 40,
      right: 40,
      textAlign: "right",
      color: "grey",
    },

    rowStart: {
      borderLeftWidth: 1,
    }
  });

  const InvoiceTitle = () => (
    <View style={styles.titleContainer}>
      <View style={styles.spaceBetween}>
        <View>
          <Text style={styles.logo}>
            ExpireMate<Text style={{ fontSize: 15 }}>.bg</Text>
          </Text>
          <Text style={styles.slogan}>
            Информационна система за управление на срокове на годност
          </Text>
        </View>
        <View style={styles.infoContainer}>
          <Text style={styles.checkNumber}>#{documentData.checkNumber}</Text>
          <Text style={styles.underlineInfo}>Номер на проверката</Text>

          <Text style={[styles.checkInfoElement, styles.bold]}>
            {documentData.storeName}
          </Text>
          <Text style={styles.underlineInfo}>{documentData.address}</Text>

          <Text style={styles.checkInfoElement}>
            Генериран на: {documentData.generationDate}
          </Text>
          {/* <Text style={[styles.checkInfoElement, { marginTop: 20 }]}>
            .......................................................
          </Text>
          <Text style={styles.underlineInfo}>Извършил проверката</Text> */}
        </View>
      </View>
    </View>
  );

  const DocumentTitle = () => (
    <View style={styles.documentTitleContainer}>
      <Text style={styles.documentTitle}>
        {documentData.documentTitle}
      </Text>
      {documentData.executionDate && (
        <Text style={styles.documentExecutionDate}>
          Изпълни проверката на: {documentData.executionDate}
        </Text>
      )}
    </View>
  );

  const TableHead = () => (
    <View style={{ width: "100%", flexDirection: "row", marginTop: 10 }} fixed>
      <View style={[styles.theader, styles.index, styles.rowStart]}>
        <Text>N:</Text>
      </View>
      <View style={[styles.theader, styles.productInfo]}>
        <Text>Описание артикул</Text>
      </View>
      <View style={[styles.theader, styles.ean]}>
        <Text>Баркод</Text>
      </View>
      <View style={[styles.theader, styles.planogram]}>
        <Text>Регал</Text>
      </View>
      <View style={[styles.theader, styles.expireDate]}>
        <Text>Текущ СГ</Text>
      </View>
      <View style={[styles.theader, styles.emptyExpireDate]}>
        <Text>Нов СГ</Text>
      </View>
      <View style={[styles.theader, styles.emptySpace]}>
        <Text>Изтичащ СГ</Text>
      </View>
      <View style={[styles.theader, styles.emptySpace]}>
        <Text>Няма</Text>
      </View>
    </View>
  );

  const TableBody = () =>
    documentData.productList?.map((product, index) => (
      <Fragment key={product?.storeProduct?.id}>
        <View
          style={[
            { width: "100%", flexDirection: "row" },
            isEven(index) ? styles.zebraWhite : styles.zebraGrey,
          ]}
        >
          <View style={[styles.tbody, styles.index, styles.rowStart]}>
            <Text>{index + 1} </Text>
          </View>
          <View style={[styles.tbody, styles.productInfo]}>
            <Text>
              {product?.storeProduct?.product?.productInfo?.vendor} -{" "}
              {product?.storeProduct?.product?.productInfo?.name}{" "}
              {product?.storeProduct?.product?.productInfo?.volume}
            </Text>
          </View>
          <View style={[styles.tbody, styles.ean]}>
            <Text>{product?.storeProduct?.product?.ean} </Text>
          </View>
          <View style={[styles.tbody, styles.planogram]}>
            <Text>{product?.storeProduct?.planogram?.name}</Text>
          </View>
          <View style={[styles.tbody, styles.expireDate, styles.bold]}>
            <Text>{expireDateView(product?.storeProduct?.expire)}</Text>
          </View>
          <View style={[styles.tbody, styles.emptyExpireDate]}>
            <Text>{"     "}</Text>
          </View>
          <View style={[styles.tbody, styles.emptySpace]}>
            <Text>{"     "}</Text>
          </View>
          <View style={[styles.tbody, styles.emptySpace]}>
            <Text>{"     "}</Text>
          </View>
        </View>
      </Fragment>
    ));

  return (
    <Document title="spisuk_s_vsi4ki_artikuli">
      <Page size="A4" style={styles.page} orientation="landscape" wrap>
        <InvoiceTitle />
        <DocumentTitle />
        <View style={styles.tableContainer}>
          <TableHead />
          <TableBody />
        </View>
        <Text
          style={styles.pageCounter}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} от ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

function isEven(num) {
  return num % 2 === 0;
}

function expireDateView(expire) {
  if (expire === null) {
    return "----";
  }
  return dateEasyRead(expire.expireDate);
}

export default ProductsCheckDocument;
