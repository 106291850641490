// @ts-ignore
import { redirect } from "react-router";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

async function startCheck(storeId, date) {
  const token = getAuthToken();
  var response = null;

  if(date === "no-quantity"){
    response = await fetch(
      Config.apiServerAddress + `/expires/newCheck?storeId=${storeId}&type=noQuantity`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  } else{
    response = await fetch(
      Config.apiServerAddress + `/expires/newCheck?storeId=${storeId}&date=${date}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  }

  if (!response.ok) {
    // @ts-ignore
    const resData = "NoPermission";
  } else {
    const resData = await response.json();
    return resData;
  }
}

export async function loader({ params }) {
  const checkInfo = await startCheck(params.storeId, params.date);
  checkInfo.storeId = parseInt(params.storeId);
  const rand = generateRandom8Digits();

  // if (!allowStartCheck(params.date)) {
  //   return redirect(`/store/${params.storeId}/check-dates`);
  // }

  // const currentCheckData = {
  //   id: checkInfo.id,
  //   startTimestamp: checkInfo.startTimestamp,
  //   checkDate: params.date,
  //   totalProducts: checkInfo.length,
  //   productsCount: checkInfo.length,
  // };

  localStorage.setItem("currentCheck", JSON.stringify(checkInfo));

  return redirect(`/store/${params.storeId}/check-dates/check-product/${rand}`);
}

// @ts-ignore
export function generateRandom8Digits() {
  // Generate a random number between 0 and 1
  // @ts-ignore
  let randomFraction = Math.random();
  
  // Scale it to fit 8 digits
  // @ts-ignore
  let random8Digits = Math.floor(randomFraction * 100000000);

  // Pad with leading zeros if necessary and convert to string
  // @ts-ignore
  let random8DigitsString = String(random8Digits).padStart(8, '0');

  return random8DigitsString;
}
