import { Alert, AlertTitle } from "@mui/material";

export default function PdfCheckNotFoundAlert (){
    return (
      <Alert variant="standard" severity="error">
        <AlertTitle>
          <b>Грешка!</b>
        </AlertTitle>
        <p>Проверка с този номер не беше намерена!</p>
      </Alert>
    );
  };