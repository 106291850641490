import React from "react";
import tableStyle from "components/style/DataTable.module.css";

export function dateStylerr(date) {
  const theDate = new Date(date);

  const currentDate = new Date();
  var tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  const past = theDate < currentDate;

  if (theDate.toDateString() === currentDate.toDateString() || past)
    return tableStyle.redCell;
  if (theDate.toDateString() === tomorrow.toDateString())
    return tableStyle.yellowCell;
  return tableStyle.greenCell;
}

export function formatDateString(dateString) {
  // Разделяме датата на година, месец и ден

  try {
    const [year, month, day] = dateString.split("-");

    // Месеците в JavaScript са индексирани от 0 до 11, затова изваждаме 1 от месеца
    const monthNames = [
      "ЯНУ",
      "ФЕВ",
      "МАР",
      "АПР",
      "МАЙ",
      "ЮНИ",
      "ЮЛИ",
      "АВГ",
      "СЕП",
      "ОКТ",
      "НОЕ",
      "ДЕК",
    ];

    // Форматираме датата
    return `${parseInt(day, 10)} ${
      monthNames[parseInt(month, 10) - 1]
    } '${year.slice(2)}г.`;
  } catch (error) {
    return "N/A";
  }
}

export function formatDateStringFromTimestamp(dateString) {
  // Разделяме датата на година, месец и ден

  try {
    const [year, month, day] = dateString.split("/");

    // Месеците в JavaScript са индексирани от 0 до 11, затова изваждаме 1 от месеца
    const monthNames = [
      "ЯНУ",
      "ФЕВ",
      "МАР",
      "АПР",
      "МАЙ",
      "ЮНИ",
      "ЮЛИ",
      "АВГ",
      "СЕП",
      "ОКТ",
      "НОЕ",
      "ДЕК",
    ];

    // Форматираме датата
    return `${parseInt(day, 10)} ${
      monthNames[parseInt(month, 10) - 1]
    } '${year.slice(2)}г.`;
  } catch (error) {
    return "N/A";
  }
}

export function dateEasyRead(inputDate) {
  if (inputDate === null) {
    return "---";
  }
  // Разделяме датата на части според тиретата
  var parts = inputDate.split("-");

  // Извличаме година, месец и ден
  var year = parts[0];
  var month = parts[1];
  var day = parts[2];

  // Форматираме ги в желания формат
  var formattedDate = day + "." + month + "." + year.slice(2);

  return formattedDate;
}

export function allowStartCheck(date) {
  const today = new Date();
  const tomorrow = new Date();
  tomorrow.setDate(today.getDate() + 1);

  const inputDate = new Date(date);

  return inputDate <= tomorrow;
}
