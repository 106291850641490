import React, { useEffect, useState } from "react";

import axios from "axios";
import Config from "../utils/Config";
import { json, defer} from "react-router-dom";

const AuthContext = React.createContext({
  isLoggedIn: false,
  user: null,
  onLogout: () => {},
  saveUser: (user) => {},
});

export const AuthContextProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn]   = useState(false);
  const [user, setUser]               = useState(null); 

  useEffect(() => {
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");
    const storedUserData                = localStorage.getItem("user");

    if (storedUserLoggedInInformation === "1") {
      setIsLoggedIn(true);
      setUser(JSON.parse(storedUserData));
    }
  }, []);

  


  const logoutHandler = async () => {
    await axios.post(Config.apiServerAddress + `/user/logout`);
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userId");
    localStorage.removeItem('user');

    setIsLoggedIn(false);
    setUser(null);
    console.log("USER LOGGED OUT!");
  };

  const saveUserHandler = async (resData) => {

    localStorage.setItem('token', resData.token);

    setUser(resData.user);
    setIsLoggedIn(true);
    console.log("USER "+ resData.user.email+ " LOGGED IN!");
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn: isLoggedIn,
        user: user,
        saveUser: saveUserHandler,
        onLogout: logoutHandler,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContext;

async function loginUser(userId) {
  const response = (await fetch(Config.apiServerAddress + `/user/use/${userId}`));

  if (!response.ok) {
    throw json(
      { message: "Could not fetch users." },
      {
        status: 500,
      }
    );
  } else {
    const resData = await response.json();
    return resData;
  }
}

export function loader(request, params) {
  const userId = params.userId;

  return defer({
    users: loginUser(userId),
  });
}
