import Loading from "components/Loading";
import { TopPageNav, TopPageNavItem } from "components/TopPageNavigation";
import PageContent from "layout/PageContent";
import { Suspense, useEffect, useState } from "react";
import {
  Await,
  Link,
  useLoaderData,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import SupliersList from "./SuppliersList";
import SnackBarAlert from "components/SnackBarAlert";
import NoEntityAlert from "components/NoEntityAlert";
import CreateSuplier from "./AddSupplierModal";
import { Edit } from "@mui/icons-material";
import EditSupplierModal from "./EditSupplierModal";
import DeleteSupplierModal from "./DeleteSupplierModal";
import { checkForLoadedPermission } from "utils/auth";
import PermissionName from "utils/enums/PermissionName";

function Supliers() {
  // @ts-ignore
  const { supliersInfoList } = useLoaderData();
  const { storeId } = useParams();
  const navigate = useNavigate();
  const selectedBusiness = JSON.parse(localStorage.getItem("selectedBusiness"));

  const [alert, setAlert] = useState({ severity: "error", message: "error" });
  const [open, setOpen] = useState(false);
  const [showCreateSuplierModal, setShowCreateSuplierModal] = useState(false);
  const [showEditSuplierModal, setShowEditSuplierModal] = useState(false);
  const [showDeleteSuplierModal, setShowDeleteSuplierModal] = useState(false);
  const [editSupplierId, setEditSupplierId] = useState(0);

  const addPermission = checkForLoadedPermission(PermissionName.ADD_SUPPLIER);

  const [deleteSupplier, setDeleteSupplier] = useState({
    id: 0,
    name: "",
  });

  var supplierDataUpdated = localStorage.getItem("supplierUpdated");
  var supplierDeleted = localStorage.getItem("supplierDeleted");
  var supplierCreated = localStorage.getItem("supplierCreated");

  useEffect(() => {
    if (supplierDeleted === "true") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Успешно изтрихте доставчика!",
      });
    } else if (supplierDeleted === "false") {
      setOpen(true);
      setAlert({
        severity: "error",
        message: "Неуспешно изтриване на доставчик!",
      });
    } else if (supplierCreated === "true") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Успешно добавихте нов доставчик!",
      });
    } else if (supplierCreated === "false") {
      setOpen(true);
      setAlert({
        severity: "error",
        message: "Неуспешно добавяне на доставчик!",
      });
    }else if (supplierDataUpdated === "true") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Данните за доставчика са актуализирани!",
      });
    } else if (supplierDataUpdated === "false") {
      setOpen(true);
      setAlert({
        severity: "error",
        message: "Неуспешно актуализиране!",
      });
    }

    setTimeout(() => {
      handleAlertClose();
    }, 3000);

    localStorage.removeItem("supplierDeleted");
    localStorage.removeItem("supplierUpdated");
    localStorage.removeItem("supplierCreated");

    refreshDataHandler();
  }, [supplierDataUpdated, supplierDeleted, supplierCreated]);

  const refreshDataHandler = () => {
    navigate(`/store/${storeId}/suppliers`);
  };

  function handleAlertClose() {
    setOpen(false);
    setAlert({ severity: "", message: "" });
  }

  const openEditModalHandler = (id) => {
    setEditSupplierId(id);
    setShowEditSuplierModal(true);
  };

  const openDeleteModalHandler = (id, name) => {
    setDeleteSupplier({ id: id, name: name });
    setShowDeleteSuplierModal(true);
  };

  return (
    <PageContent pageTitle="Доставчици към обекта">
      <TopPageNav>
        <TopPageNavItem>
          {addPermission && (
            <Link
              className="btn btn-success mx-1 btn-sm"
              to={" "}
              onClick={() => setShowCreateSuplierModal(true)}
            >
              Добави доставчик
            </Link>
          )}
        </TopPageNavItem>
      </TopPageNav>
      <SnackBarAlert open={open} alert={alert} />
      <Suspense fallback={<Loading />}>
        {showCreateSuplierModal && (
          <CreateSuplier
            onClose={() => setShowCreateSuplierModal(false)}
            onAdd={() => refreshDataHandler()}
          />
        )}
        {showEditSuplierModal && (
          <EditSupplierModal
            onClose={() => setShowEditSuplierModal(false)}
            onAdd={() => refreshDataHandler()}
            supplierId={editSupplierId}
          />
        )}
        {showDeleteSuplierModal && (
          <DeleteSupplierModal
            onCancel={() => setShowDeleteSuplierModal(false)}
            onAdd={() => refreshDataHandler()}
            supplier={deleteSupplier}
          />
        )}
        <Await resolve={supliersInfoList}>
          {(supliersInfoList) => {
            console.log(supliersInfoList);
            // supliersInfoList.status = 500;
            // if (supliersInfoList.status === 500) {
            //   return (
            //     <NoEntityAlert
            //       heading="Към обекта няма добавени доставици!"
            //       toLink={`create-planogram`}
            //       onClickGoText="Създай нов"
            //     >
            //       Моля добавете регал за да започнете!
            //     </NoEntityAlert>
            //   );
            // } else {
            //   return <SupliersList supliers={supliersInfoList} />;
            // }
            return (
              <SupliersList
                supliers={supliersInfoList}
                openEditModal={(supplierId) => openEditModalHandler(supplierId)}
                openCreateModal={() => setShowCreateSuplierModal(true)}
                openDeleteModal={(supplierId, supplierName) =>
                  openDeleteModalHandler(supplierId, supplierName)
                }
              />
            );
          }}
        </Await>
      </Suspense>
    </PageContent>
  );
}

export default Supliers;
