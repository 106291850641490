import React from "react";
import "./style/BusinessDashboard.css";
import UnderConstruction from "components/UnderConstruction";
import PageContent from "layout/PageContent";

export default function BusinessDashboard(props){
  return (
    <PageContent pageTitle="UnderConstruction">
      <UnderConstruction />
    </PageContent>
  );
};

// export default BusinessDashboard;
