import { Badge } from "@mui/material";
import MailIcon from '@mui/icons-material/Mail';
import ChecklistIcon from "@mui/icons-material/Checklist";
// import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarViewMonthIcon from "@mui/icons-material/CalendarViewMonth";
import style from "./style/Badges.module.css";

export default function Badges() {
//   const user = getUser();
//   const fullName = `${user.firstName} ${user.lastName}`;

  return (
    <div>
      <Badge className={style.badge} badgeContent={4} color="success">
        <MailIcon className={style.icon} color="action" />
      </Badge>
      {/* <Badge className={style.badge} badgeContent={4} color="primary">
        <PeopleAltIcon className={style.icon} color="action" />
      </Badge> */}
      <Badge className={style.badge} badgeContent={2} color="error">
        <ChecklistIcon className={style.icon} color="disabled" />
      </Badge>
    </div>
  );
}
