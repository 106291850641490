import Modal from "../components/Modal";

const ConfirmModal = (props) => {
  return (
    <Modal>
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLongTitle">
            Confirm delete user {props.userId}
          </h4>
        </div>
        <div className="modal-body my-3 ms-3">
          Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum ac
          ante tempor, bibendum ante auctor, accumsan est. Aenean dictum neque
          nec leo fringilla mattis. Nulla facilisi. Donec metus nisl, maximus
          vel tincidunt et, dictum sit amet urna. In tristique, lectus.
        </div>
        <div className="modal-footer">
          <button
            type="button"
            className="btn btn-outline-secondary ms-1"
            data-dismiss="modal"
            onClick={props.onCancel}
          >
            Cancel
          </button>
          <button type="button" className="btn btn-danger ms-1" onClick={props.onYes}>
            Delete user
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
