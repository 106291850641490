import style from "./style/DataUpdating.module.css";
import { GridLoader } from "react-spinners";

const DataLoading = (props) => {
  return (
    <div className={style.mainContainer}>
        <span className={style.title}>
            {props.title ? props.title : "Данните се зареждат"}
        </span>
        <span className={style.info}>
            {props.info ? props.info : "Моля изчакайте няколко секунди..."}
        </span>
      <span className={style.loading}>
        <GridLoader color="#00ce5d" loading size={40} speedMultiplier={1} />
      </span>
    </div>
  );
};

export default DataLoading;
