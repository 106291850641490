import { defer, json } from "react-router";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";

export function loader({ request, params }) {

    return defer({
      supliersInfoList: getSuppliers(params.storeId),
    });
  }
  
async function getSuppliers(storeId) {
    const token = getAuthToken();

    // const supliers = [
    //   {
    //     id: 1,
    //     name: "suplier1",
    //     companyName: "company1",
    //     mobilePhone: "0888888888",
    //     email: "suplier1@mail.com",
    //     productsCount: 10,
    //   },
    //   {
    //     id: 2,
    //     name: "suplier2",
    //     companyName: "company2",
    //     mobilePhone: "08888848884",
    //     email: "suplier2@mail.com",
    //     productsCount: 20,
    //   },
    //   {
    //     id: 3,
    //     name: "suplier3",
    //     companyName: "company3",
    //     mobilePhone: "08888848884",
    //     email: "suplier3@mail.com",
    //     productsCount: 30,
    //   },
    //   {
    //     id: 4,
    //     name: "suplier4",
    //     companyName: "company4",
    //     mobilePhone: "08888848884",
    //     email: "suplier4@mail.com",
    //     productsCount: 40,
    //   },
    //   {
    //     id: 5,
    //     name: "suplier5",
    //     companyName: "company5",
    //     mobilePhone: "08888848884",
    //     email: "suplier5@mail.com",
    //     productsCount: 50,
    //   }
    // ];
    
    // return supliers;
  
    const response = await fetch(
      Config.apiServerAddress +
        `/suppliers?storeId=${storeId}`,
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
      }
    );
  
    if (!response.ok) {
      return {
        message: "Няма отговор от сървъра",
        status: 500
      };
    } else {
      const suppliers = await response.json();
      return suppliers;
    }
  }

  export { getSuppliers };