import { redirect } from "react-router";
import { getAuthToken } from "utils/auth";
import Config from "utils/Config";

export async function action({ request }) {
    // @ts-ignore
    const token = getAuthToken();
  
    const data = await request.formData();
    const response = {
      code: "",
      message: "",
    };
    const newPlanogramData = {
      storeId: data.get("storeId"),
      name: data.get("planogramName"),
    };
  
    if (newPlanogramData.name.length === 0) {
      response.code = "422";
      response.message =
        "Моля въведете име на планограмата която искате да създадете!";
    } else if (newPlanogramData.name.length < 5) {
      response.code = "422";
      response.message = "Името е твърде късо";
    }
  
    const fetchResponse = await fetch(
      Config.apiServerAddress + "/planograms",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(newPlanogramData),
      }
    );
  
    if (fetchResponse.status === 422 || fetchResponse.status === 401) {
      response.code = "422";
      response.message = "Нещо се обърка. Опитайте отново!";
      return response;
    } else {
      return redirect(`/store/${newPlanogramData.storeId}/planograms?created=true`);
    }
  }