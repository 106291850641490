import React, { useEffect, useState } from "react";
import { AlertTitle } from "@mui/material";
import Alert from "@mui/material/Alert";
import Loading from "components/Loading";
import { TopPageNav } from "components/TopPageNavigation";
import PageContent from "layout/PageContent";
import { useParams } from "react-router";

import tableStyle from "components/style/DataTable.module.css";
import { dateEasyRead, formatDateString } from "utils/DateStyler";
import { getAuthToken } from "utils/auth";
import Config from "utils/Config";
import PageSubtitle from "components/PageSubTitle";

import style from "./style/CheckReport.module.css";

import InfoIcon from "@mui/icons-material/Info";
import { calculateCheckMinutes, formatStatusString } from "./ChecksReferences";
import SnackBarAlert from "components/SnackBarAlert";

export const CheckReport = () => {
  const [checkData, setCheckData] = useState();
  const [checksReferences, setChecksReferences] = useState([]);
  const [checkDataLoading, setCheckDataLoading] = useState(true);
  const [checkReferencesLoading, setCheckReferencesLoading] = useState(true);
  const { checkId } = useParams();
  const { storeId } = useParams();

  const [alert, setAlert] = useState({ severity: "", message: "" });
  const [open, setOpen] = useState(false);
  let savedStatus = localStorage.getItem("checkStatus");


  useEffect(() => {
    const fetchCheckData = async () => {
      const data = await checkDataLoader(checkId, storeId);
      setCheckData(data);
      setCheckDataLoading(false);
    };

    const fetchReferencesData = async () => {
      setCheckReferencesLoading(true);
      const data = await checkReferencesDataLoader(checkId, storeId);
      setChecksReferences(data);
      setCheckReferencesLoading(false);
    };

    fetchCheckData().catch((error) => {
      console.error("Error fetching checkData:", error);
      setCheckDataLoading(false);
      setCheckData(null);
    });

    if (checkData !== null) {
      fetchReferencesData().catch((error) => {
        console.error("Error fetching checkReferences:", error);
        setCheckReferencesLoading(false);
        setChecksReferences(null);
      });
    }

    if (savedStatus !== null && savedStatus === "check-done") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Проверката е извършена успешно! Може да прегледате доклада.",
      });
    }

    if (savedStatus !== null && savedStatus === "user-abort") {
      setOpen(true);
      setAlert({
        severity: "warning",
        message: "Проверката е прекъсната!",
      });
    }

    if (savedStatus !== null && savedStatus === "system-abort") {
      setOpen(true);
      setAlert({
        severity: "error",
        message: "Проверката е прекъсната от системна грешка!",
      });
    }

    

    if (savedStatus !== null) {
      localStorage.removeItem("checkStatus");
    }
  }, [savedStatus, open]);

  return (
    <PageContent pageTitle="Доклад проверка">
      <SnackBarAlert open={open} alert={alert} />
      <TopPageNav to={`/store/${storeId}/checks/references/page/1`}/>
      {checkDataLoading && <Loading />}
      {!checkDataLoading && checkData === null && <CheckNotFound />}
      {!checkDataLoading && checkData && (
        <ViewCheckInfo checkData={checkData} />
      )}
      {checkData !== null && checkReferencesLoading && <Loading />}
      {!checkReferencesLoading && checkData && (
        <CheckReferencesTable checksReferences={checksReferences} />
      )}
    </PageContent>
  );
};

const ViewCheckInfo = ({ checkData }) => {
  return (
    <div className="py-2">
      <PageSubtitle>Информация за проверката</PageSubtitle>
      <div className={style.checkInfoContainer}>
        <CheckInfoElement label="Начало на проверка">
          {formatTimeStamp(checkData.startTimestamp)}
        </CheckInfoElement>
        <CheckInfoElement label="Край на проверка">
          {formatTimeStamp(checkData.endTimestamp)}
        </CheckInfoElement>
        <CheckInfoElement label="Проверка на дата">
          {dateEasyRead(checkData.checkDate)}
        </CheckInfoElement>
        <CheckInfoElement label="Артикули за проверка">
          {checkData.totalProductsCount}
        </CheckInfoElement>
        <CheckInfoElement label="Проверени артикули">
          {checkData.checkedProductsCount}
        </CheckInfoElement>
        <CheckInfoElement label="Статус на проверката" id={formatStatusColor(checkData.status)}>
          {formatStatusString(checkData.status)}
        </CheckInfoElement>
        <CheckInfoElement label="Продължителност на проверката">
          {calculateCheckMinutes(checkData.startTimestamp, checkData.endTimestamp)} минути
        </CheckInfoElement>
        <CheckInfoElement label="Извършил проверката">
          {checkData.employeeName}
        </CheckInfoElement>
        {/* <div className={style.checkInfoEmptyElement}></div> */}
      </div>
    </div>
  );
};

const CheckInfoElement = (props) => {
  var value = props.children ? props.children : "---";
  return (
    <div className={style.checkInfoElement}>
      <p id={style.title}>
        <InfoIcon id={style.icon} />
        {props.label}
      </p>
      <p className={style.value} id={props.id ? props.id : " "}>{value}</p>
    </div>
  );
};

const CheckReferencesTable = ({ checksReferences }) => {
  if (checksReferences.length === 0) {
    return <NoReportFound />;
  } else {
    return (
      <>
        <PageSubtitle>Проверени артикули</PageSubtitle>
        <div className="py-2 table-responsive-xl">
          <table className={tableStyle.table}>
            <thead>
              <tr>
                <th>Производител</th>
                <th>Артикул</th>
                <th>Обем</th>
                <th>EAN</th>
                <th>Текущ СГ</th>
                <th>Нов СГ</th>
                <th>Изтичащи бройки</th>
                <th>Без наличност</th>
              </tr>
            </thead>
            <tbody>
              {checksReferences.map((checkReference) => (
                <tr key={checkReference.id}>
                  <td>{checkReference.productVendor}</td>
                  <td>{checkReference.productName}</td>
                  <td>{checkReference.productVolume}</td>
                  <td className={tableStyle.centerMe}>
                    {checkReference.productEan}
                  </td>
                  <td
                    className={
                      checkReference.lastExpireDate && tableStyle.dateCell
                    }
                    id={
                      checkReference.lastExpireDate
                        ? tableStyle.greenCell
                        : tableStyle.redCell
                    }
                  >
                    {checkReference.lastExpireDate !== null
                      ? dateEasyRead(checkReference.lastExpireDate)
                      : "Не е въведен"}
                  </td>
                  <td
                    className={
                      checkReference.newExpireDate && tableStyle.dateCell
                    }
                    id={
                      checkReference.newExpireDate
                        ? tableStyle.greenCell
                        : tableStyle.redCell
                    }
                  >
                    {checkReference.newExpireDate !== null
                      ? dateEasyRead(checkReference.newExpireDate)
                      : "Не е въведен"}
                  </td>
                  <td
                    id={
                      checkReference.expiredFound
                        ? tableStyle.redCell
                        : tableStyle.greenCell
                    }
                  >
                    {checkReference.expiredFound ? "ДА" : "НЕ"}
                  </td>
                  <td
                    id={
                      checkReference.noQuantity
                        ? tableStyle.redCell
                        : tableStyle.greenCell
                    }
                  >
                    {checkReference.noQuantity ? "ДА" : "НЕ"}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
};

const CheckNotFound = () => {
  return (
    <div className="mb-2">
      <Alert variant="standard" severity="error">
        <AlertTitle>
          <b>Грешка!</b>
        </AlertTitle>
        Доклада за проверката не е наличен или не е достъпен. Моля опитайте
        отново.
      </Alert>
    </div>
  );
};
const NoReportFound = () => {
  return (
    <div className="mb-2">
      <Alert variant="standard" severity="error">
        <AlertTitle>
          <b>Няма информация!</b>
        </AlertTitle>
        За тази проверка не е налична информация за проверени артикули.
      </Alert>
    </div>
  );
};

async function checkDataLoader(checkId, storeId) {
  const token = getAuthToken();

  try {
    const request = await fetch(
      Config.apiServerAddress +
        `/checks/single?storeId=${storeId}&checkId=${checkId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!request.ok) {
      throw new Error(`HTTP error! Status: ${request.status}`);
    }

    const data = await request.json();
    return data;
  } catch (error) {
    console.error("Error fetching checkData:", error);
    throw error;
  }
}

async function checkReferencesDataLoader(checkId, storeId) {
  const token = getAuthToken();

  try {
    const request = await fetch(
      Config.apiServerAddress +
        `/checks/report?storeId=${storeId}&checkId=${checkId}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!request.ok) {
      throw new Error(`HTTP error! Status: ${request.status}`);
    }

    const data = await request.json();
    return data;
  } catch (error) {
    console.error("Error fetching checkReferences:", error);
    throw error;
  }
}

//make function -> input startTimestamp and endTimestamp like this 2024/05/28 19:58:46, output object with start date, startTime, endTime,
const formatTimeStamp = (timeStamp) => {
  if (timeStamp === null) {
    return "---";
  }
  //get date in format yyyy-mm-dd and time in format hh:mm - this is timestamp original value 2024/07/02 15:03:18
  var date = timeStamp.split(" ")[0];
  //get date in format yyyy-mm-dd
  date = date.split("/").join("-");
  const time = timeStamp.split(" ")[1].substring(0, 5);

  return (
    <span>
      {dateEasyRead(date)}  -  {time}
    </span>
  );
};

const formatStatusColor = (status) => {
  switch (status) {
    case "finish":
      return style.finishStatus;
    case "progress":
      return style.progressStatus;
    case "abort":
      return style.abortStatus;
    default:
      return style.progressStatus;
  }
};
