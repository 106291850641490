export function convertToISODate(dateString) {
  var parts = dateString.split(".");
  var day = parts[0];
  var month = parts[1];
  var year = parts[2];

  // Определяме дали годината е в двуцифрения или четирицифрения формат
  if (year.length === 2) {
    // Ако годината е в двуцифрения формат, преобразуваме я в четирицифрения
    var currentYear = new Date().getFullYear().toString();
    var century = currentYear.slice(0, 2);
    year = century + year;
  }

  // Формираме ISO формат на датата
  var isoDate = year + "-" + month + "-" + day;
  return isoDate;
}

export function formatDate(inputDateField) {
  if (!inputDateField || !inputDateField.value) {
      console.error("Invalid input field provided");
      return;
  }

  var dateValue = inputDateField.value;

  dateValue = dateValue.replace(/\D/g, "");

  if (dateValue.length < 4 || dateValue.length > 6) {
    inputDateField.value = "";
    return;
  }

  var today = new Date();
  var currentYear = today.getFullYear().toString().slice(-2);
  var day = dateValue.slice(0, 2);
  var month = dateValue.slice(2, 4);

  console.log("dateValue.length: " + dateValue.length);
  if (dateValue.length === 4) {
    var inputDate = new Date(today.getFullYear(), month - 1, day);

    if (inputDate < today) {
      inputDateField.value = dateCheck(
        day + "." + month + "." + (parseInt(currentYear) + 1)
      );
      console.log(
        "dateCheck: " +
          dateCheck(day + "." + month + "." + (parseInt(currentYear) + 1))
      );
    } else {
      inputDateField.value = dateCheck(day + "." + month + "." + currentYear);
      console.log(
        "dateCheck: " + dateCheck(day + "." + month + "." + currentYear)
      );
    }
    return;
  } else if (dateValue.length === 6) {
    var year = dateValue.slice(4, 6);
    inputDateField.value = dateCheck(day + "." + month + "." + year);
    return;
  } else{
    inputDateField.value = "";
  }
}


export function isDateValid(date) {
  if (date === null || date === undefined || date === "") {
    return false;
  }

  if (date.length === 8) {
    date = convertToISODate(date);
  }

  if (date.length !== 10) {
    return false;
  }
  var parts = date.split("-");
  if (parts.length !== 3) {
    return false;
  }
  var year = parseInt(parts[0]);
  var month = parseInt(parts[1]);
  var day = parseInt(parts[2]);

  // Проверка за числови стойности
  if (isNaN(year) || isNaN(month) || isNaN(day)) {
    return false;
  }

  // Проверка за валидни стойности
  if (year < 2023 || year > 2100) {
    return false;
  }
  if (month < 1 || month > 12) {
    return false;
  }
  if (day < 1 || day > 31) {
    return false;
  }

  // Проверка за февруари
  if (month === 2) {
    if (day > 29) {
      return false;
    }
    if (
      day === 29 &&
      !((year % 4 === 0 && year % 100 !== 0) || year % 400 === 0)
    ) {
      return false;
    }
  }
  // Проверка за април, юни, септември и ноември
  if ([4, 6, 9, 11].includes(month)) {
    if (day > 30) {
      return false;
    }
  }
  return true;
}

function dateCheck(dateString) {
  if (!isDateValid(dateString)) {
    return null;
  }
  return dateString;
}
