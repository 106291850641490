import React, { useState, useEffect } from "react";
import Loading from "components/Loading";
import { TopPageNav, TopPageNavItem } from "components/TopPageNavigation";
import tableStyle from "components/style/DataTable.module.css";
import productCardStyle from "../checkDate/style/CheckProduct.module.css";
import style from "./style/ProductReference.module.css";
import formStyle from "components/style/Form.module.css";
import PageContent from "layout/PageContent";
import { useNavigate, useParams } from "react-router";
import { getAuthToken } from "utils/auth";
import Config from "utils/Config";
import PageSubtitle from "components/PageSubTitle";
import { dateEasyRead, formatDateString } from "utils/DateStyler";
import { isProductDisabled } from "utils/ProductUtils";
import TableLoading from "components/TableLoading";
import Alert from "@mui/material/Alert";
import { AlertTitle } from "@mui/material";
import { convertToISODate, formatDate } from "utils/DateFormater";
import BarcodeScannerModal from "components/BarcodeScannerModal";
// import BarcodeScannerModal from "components/BarcodeScannerModal";

export default function ProductReference() {
  const { productEan } = useParams();
  const { storeId } = useParams();
  const [product, setProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const [productFound, setProductFound] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [scannedEan, setScannedEan] = useState(null);

  async function fetchProduct() {
    try {
      const fetchedProduct = await getProductByEan(productEan, storeId);
      setProduct(fetchedProduct);
      setProductFound(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <PageContent pageTitle="Справка за артикул ">
      <TopPageNav>
        <TopPageNavItem />
      </TopPageNav>
      {showScanner && (
        <BarcodeScannerModal
          onClose={() => setShowScanner(false)}
          onReturnData={(data) => setScannedEan(data)}
        />
      )}
      {!productEan && (
        <ProductDataForm
          showScanner={() => setShowScanner(true)}
          ean={scannedEan}
        />
      )}
      {productEan !== undefined && <ProductData ean={productEan} />}
    </PageContent>
  );
}

const ProductDataForm = (props) => {
  const navigate = useNavigate();
  const [ean, setEan] = useState(props.ean);

  // Clear EAN on component mount
  useEffect(() => {
    setEan("");
  }, []);

  // Update EAN if props.ean changes
  useEffect(() => {
    if (props.ean) {
      setEan(props.ean);
    }
  }, [props.ean]);

  function submitDataHandler(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    if (!formData.get("expireDate")) {
      navigate(`${formData.get("ean")}`);
    } else {
      navigate(
        `${formData.get("ean")}/${convertToISODate(formData.get("expireDate"))}`
      );
    }
  }

  return (
    <>
      <ReferenceInfoAlert />
      <form
        method="post"
        onSubmit={submitDataHandler}
        className={formStyle.formContainer}
      >
        <div>
          <label>EAN на артикула*</label>
          <input
            type="number"
            name="ean"
            value={ean || ""}
            onChange={(e) => setEan(e.target.value)}
            className="form-control mb-3"
            id={formStyle.eanInput}
            required
          />
          <button
            type="button"
            className="btn btn-warning"
            onClick={() => props.showScanner(true)}
            id={formStyle.eanScannerButton}
          >
            <i className="fa-solid fa-camera"></i>
          </button>
        </div>
        <div>
          <label>Срок на годност</label>
          <input
            type="text"
            className="form-control mb-3"
            placeholder="ДДММ"
            name="expireDate"
            onBlur={(e) => formatDate(e.target)}
          />
        </div>
        <div>
          <button type="submit" className="btn btn-success mx-auto">
            Провери
          </button>
        </div>
      </form>
    </>
  );
};

const ProductData = (props) => {
  const { productEan } = useParams();
  const { storeId } = useParams();
  const { expireDate } = useParams();
  const [product, setProduct] = useState(null);
  const [productReferences, setProductReferences] = useState(null);
  const [productLoading, setProductLoading] = useState(true);
  const [productReferenceLoading, setProductReferenceLoading] = useState(true);
  const [productFound, setProductFound] = useState(false);
  const [productReferencesFound, setProductReferencesFound] = useState(false);
  const resultsLimit = 15;
  const headerElements = [
    "Дата и час",
    "Действие",
    "Текущ СГ",
    "Нов СГ",
    "Изтичащи бройки",
    "Без наличност",
    "Служител",
  ];

  useEffect(() => {
    var productId = null;
    async function fetchProduct() {
      try {
        const fetchedProduct = await getProductByEan(productEan, storeId);
        setProduct(fetchedProduct);
        setProductFound(true);
        productId = fetchedProduct.id;
        setProductLoading(false);
      } catch (error) {
        setProductLoading(false);
      }
    }

    async function fetchProductReference(id, expireDate) {
      try {
        const fetchedProductReference = await getProductReference(
          id,
          storeId,
          expireDate,
          resultsLimit
        );
        setProductReferences(fetchedProductReference);
      } catch (error) {
        console.error("Error fetching product reference:", error);
      }
      setProductReferenceLoading(false);
    }

    fetchProduct().then(() => {
      fetchProductReference(productId, expireDate);
    });
  }, [productEan]); // Add only the necessary dependencies
  return (
    <>
      <PageSubtitle value="Информация за артикула" />
      {!productLoading ? <ProductCard product={product} /> : <Loading />}
      {product && (
        <>
          {!expireDate && <PageSubtitle value="История на артикула" />}
          {expireDate && (
            <PageSubtitle value={`История - СГ: ${dateEasyRead(expireDate)}`} />
          )}
          {!productReferenceLoading ? (
            <ProductDataTable
              productReferences={productReferences}
              resultsLimit={resultsLimit}
              date={expireDate}
            />
          ) : (
            <TableLoading headerElements={headerElements} />
          )}
        </>
      )}
    </>
  );
};

const ProductCard = (props) => {
  const { product } = props;
  if (product === null) {
    return <NoProductFound />;
  } else {
    return (
      <>
        <div className={productCardStyle.productInfoContainer}>
          <span className={productCardStyle.vendor}>
            {product.product.productInfo.vendor}
          </span>
          <span className={productCardStyle.productName}>
            {product.product.productInfo.name}
          </span>
          <span className={productCardStyle.productInfoElement}>
            <i
              className="fa-sharp fa-solid fa-barcode"
              id={productCardStyle.icon}
            ></i>
            <b> {product.product.ean}</b>
          </span>
          <span className={productCardStyle.productInfoElement}>
            <i
              className="fa-solid fa-boxes-packing"
              id={productCardStyle.icon}
            ></i>{" "}
            <b>{product.product.productInfo.volume}</b>
          </span>
          <span className={productCardStyle.productInfoElement}>
            <i
              className="fa-solid fa-table-cells-large"
              id={productCardStyle.icon}
            ></i>{" "}
            <b>{product.planogram.name}</b>
          </span>
          <br />
          {product.supplier !== null && (
            <span className={productCardStyle.productInfoElement}>
              <i className="fa-solid fa-truck" id={productCardStyle.icon}></i>
              <b>{product.supplier.name}</b>
            </span>
          )}

          {product.expire !== null && (
            <span className={productCardStyle.expireElement}>
              Срок на годност:{" "}
              <h3>{dateEasyRead(product.expire.expireDate)}</h3>
            </span>
          )}
          {product.expire === null && !isProductDisabled(product) && (
            <span
              className={productCardStyle.expireElement}
              id={productCardStyle.noQuantity}
            >
              <i>Няма наличност от артикула!</i>
            </span>
          )}
          {product.expire === null && isProductDisabled(product) && (
            <span
              className={productCardStyle.expireElement}
              id={productCardStyle.noQuantity}
            >
              <i>Деактивиран артикул!</i>
            </span>
          )}
        </div>
      </>
    );
  }
};

const ProductDataTable = (props) => {
  if (props.productReferences.length === 0) {
    return <NoProductReferenceFound date={props.date} />;
  } else {
    return (
      <>
        <div className="py-2 table-responsive-xl">
          <table className={tableStyle.table}>
            <thead>
              <tr>
                <th>Дата и час</th>
                <th>Действие</th>
                <th>Текущ СГ</th>
                <th>Нов СГ</th>
                <th>Изтичащи бройки</th>
                <th>Без наличност</th>
                <th>Служител</th>
              </tr>
            </thead>
            <tbody>
              {props.productReferences.map((productReference) => (
                <tr key={productReference.id}>
                  <td>{formatTimeStamp(productReference.timeStamp)}</td>
                  <td
                    className={tableStyle.centerMe}
                    id={formatActionColor(productReference.action)}
                  >
                    {formatAction(productReference.action)}
                  </td>
                  <td
                    className={
                      productReference.lastExpireDate && tableStyle.dateCell
                    }
                    id={
                      productReference.lastExpireDate
                        ? tableStyle.greenCell
                        : tableStyle.redCell
                    }
                  >
                    {productReference.lastExpireDate !== null
                      ? dateEasyRead(productReference.lastExpireDate)
                      : "Не е въведен"}
                  </td>
                  <td
                    className={
                      productReference.newExpireDate && tableStyle.dateCell
                    }
                    id={
                      productReference.newExpireDate
                        ? tableStyle.greenCell
                        : tableStyle.redCell
                    }
                  >
                    {productReference.newExpireDate !== null
                      ? dateEasyRead(productReference.newExpireDate)
                      : "Не е въведен"}
                  </td>
                  <td
                    id={
                      productReference.expiredFound
                        ? tableStyle.redCell
                        : tableStyle.greenCell
                    }
                  >
                    {productReference.expiredFound ? "ДА" : "НЕ"}
                  </td>
                  <td
                    id={
                      productReference.noQuantity
                        ? tableStyle.redCell
                        : tableStyle.greenCell
                    }
                  >
                    {productReference.noQuantity ? "ДА" : "НЕ"}
                  </td>
                  <td className={tableStyle.centerMe}>
                    {productReference.employeeName}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </>
    );
  }
};

const NoProductFound = (productId) => {
  return (
    <div className="mb-2">
      <Alert variant="standard" severity="error">
        <AlertTitle>
          <b>Артикулът не е намерен!</b>
        </AlertTitle>
        Артикула който търсите не е намерен в системата.
        <br />
        <br />
        Моля, проверете дали сте въвели правилния EAN или се свържете с
        администратора.
      </Alert>
    </div>
  );
};

const ReferenceInfoAlert = () => {
  return (
    <Alert variant="standard" severity="info">
      <AlertTitle>
        <b>Информация</b>
      </AlertTitle>
      <p>За да видите историята на артикул, моля въведете EAN </p>
      <p>
        Добавете срок на годност за да видите кой, кога и дали е обработил
        артикула с този СГ.
      </p>
    </Alert>
  );
};

const NoProductReferenceFound = ({ date }) => {
  if (date !== undefined && date !== null) {
    return (
      <Alert variant="standard" severity="warning">
        <AlertTitle>
          <b>Датата не е намерена</b>
        </AlertTitle>
        Търсената от вас дата не е намерена в историята на артикула.
        <br />
        <br />
        Моля, проверете дали сте въвели правилна дата или се свържете с
        администратора.
      </Alert>
    );
  } else {
    return (
      <Alert variant="standard" severity="info">
        <AlertTitle>
          <b>Артикулът няма история!</b>
        </AlertTitle>
        За този артикул все още няма история. Това може да се дължи на новост на
        артикула или на липса на актуализации.
        <br />
        <br />
        Моля, проверете по-късно или се свържете с администратора.
      </Alert>
    );
  }
};

async function getProductByEan(productEan, storeId) {
  const token = getAuthToken();

  try {
    const request = await fetch(
      Config.apiServerAddress +
        `/storeproducts/single?storeId=${storeId}&productId=${productEan}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!request.ok) {
      throw new Error(`HTTP error! Status: ${request.status}`);
    }

    const data = await request.json();
    return data;
  } catch (error) {
    console.error("Error fetching product:", error);
    throw error;
  }
}

async function getProductReference(productId, storeId, expireDate, limit) {
  const token = getAuthToken();
  var date = expireDate ? expireDate : "all";

  try {
    const request = await fetch(
      Config.apiServerAddress +
        `/storeproducts/references?storeId=${storeId}&productId=${productId}&expireDate=${date}&limit=${limit}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (!request.ok) {
      throw new Error(`HTTP error! Status: ${request.status}`);
    }

    const data = await request.json();
    return data;
  } catch (error) {
    console.error("Error fetching product reference:", error);
    throw error;
  }
}

const formatTimeStamp = (timeStamp) => {
  //get date in format yyyy-mm-dd and time in format hh:mm - this is timestamp original value 2024/07/02 15:03:18
  var date = timeStamp.split(" ")[0];
  //get date in format yyyy-mm-dd
  date = date.split("/").join("-");
  const time = timeStamp.split(" ")[1].substring(0, 5);

  return (
    <div className={style.dateAndTimeConatiner}>
      <span className={style.date}>{formatDateString(date)}</span>
      <span className={style.time}>{time}</span>
    </div>
  );
};

const formatAction = (action) => {
  if (action === "check") {
    return "Проверка";
  } else if (action === "edit") {
    return "Редакция";
  } else if (action === "pdf_check") {
    return "PDF Проверка";
  } else {
    return action;
  }
};

const formatActionColor = (action) => {
  if (action === "check") {
    return tableStyle.greenCell;
  } else if (action === "edit") {
    return tableStyle.yellowCell;
  } else if (action === "pdf_check") {
    return tableStyle.blueCell;
  }
};
