import style from  "./style/Loading.module.css";
import { GridLoader, PropagateLoader } from "react-spinners";

const Loading = () => {
    return (
        <span className={style.loadingContainer}>
            {/* <PropagateLoader color="#00ce5d" loading size={30} speedMultiplier={1} /> */}
            <GridLoader color="#74f281" loading size={50} speedMultiplier={1} />
        </span>
    );
}

export default Loading;