// @ts-ignore
import React, { useEffect, useState } from "react";
import tableStyle from "components/style/DataTable.module.css";
import PageContent from "layout/PageContent";
import { TopPageNav } from "components/TopPageNavigation";
import PdfCheckNotFoundAlert from "./components/PdfCheckNotFoundAlert";
import { useNavigate, useParams } from "react-router";
import { Switch } from "@mui/material";
import { dateEasyRead } from "utils/DateStyler";
import { formatDate } from "utils/DateFormater";
import Loading from "components/Loading";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";
// @ts-ignore
import DataUpdating from "components/DataUpdating";
import DataUpdatingModal from "components/DataUpdatingModal";
import { Link } from "react-router-dom";

export default function PdfCheckInput() {
  const { storeId } = useParams();
  const { checkNumber } = useParams();
  const navigate = useNavigate();
  const [checkData, setCheckData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dataImport, setDataImport] = useState(false);

  useEffect(() => {
    getCheckData(checkNumber, storeId)
      .then((data) => {
        data.employee.user = null;
        setCheckData(data);
        setLoading(false);
      })
      .catch((error) => {
        console.log(error);
        setCheckData(null);
        setLoading(false);
      });
  }, [checkNumber]);

  const handleInputChange = (index, field, value) => {
    const updatedCheckData = { ...checkData };
    // @ts-ignore
    updatedCheckData.pdfCheckElements[index][field] = value;
    setCheckData(updatedCheckData);
  };

  const importCheckDataHandler = async () => {
    console.log(`handler_importCheckData___call_${random4digit()}`);
    setDataImport(true);
    try {
      await importCheckData(checkData, storeId);
      localStorage.setItem("pdfCheckImported", "true");
    } catch (error) {
      localStorage.setItem("pdfCheckImported", "false");
    }
    setDataImport(false);

    navigate(`/store/${storeId}/pdf-checks`);
  }

  return (
    <PageContent pageTitle="Въвеждане на PDF проверка">
      <TopPageNav />
      {dataImport && <DataUpdatingModal />}
      {loading && <Loading />}
      {checkData && !loading && (
        <ProductsTable
          // @ts-ignore
          pdfCheckElements={checkData.pdfCheckElements}
          onInputChange={handleInputChange}
          onImport={importCheckDataHandler}
          storeId={storeId}
        />
      )}
      {checkData === null && <PdfCheckNotFoundAlert />}
    </PageContent>
  );
}

const ProductsTable = ({ pdfCheckElements, onInputChange, onImport, storeId }) => {
  return (
    <div className="py-2 table-responsive-sm">
      <table className={tableStyle.table}>
        <thead>
          <tr>
            <th>N</th>
            <th>Описание артикул</th>
            <th>Баркод</th>
            <th>Текущ СГ</th>
            <th>Нов СГ</th>
            <th>Изтичащ</th>
            <th>Няма</th>
          </tr>
        </thead>
        <tbody>
          {pdfCheckElements.map((checkElement, index) => {
            const productInfo = checkElement.storeProduct.product.productInfo;
            const productName = `${productInfo.vendor} ${productInfo.name} ${productInfo.volume}`;

            return (
              <tr key={index} style={{ padding: 0, height: 20 }}>
                <td>{checkElement.orderNumber}</td>
                <td>{productName}</td>
                <td className={tableStyle.centerMe}>
                  {checkElement.storeProduct.product.ean}
                </td>
                <td className={tableStyle.centerMe + " " + tableStyle.dateCell}>
                  {checkElement.storeProduct.expire ? dateEasyRead(checkElement.storeProduct.expire.expireDate) : "---"}
                </td>
                <td style={{ width: 150 }}>
                  <input
                    type="text"
                    name="newExpireDate"
                    className="form-control form-control-sm"
                    style={{ width: 150 }}
                    placeholder="ДДММ"
                    onBlur={(e) => {
                      formatDate(e.target);
                      onInputChange(index, "newExpireDate", e.target.value);
                    }}
                    required
                  />
                </td>
                <td className={tableStyle.centerMe}>
                  <Switch
                    color="error"
                    checked={checkElement.isExpired}
                    onChange={(e) =>
                      onInputChange(index, "expiredFound", e.target.checked)
                    }
                  />
                </td>
                <td className={tableStyle.centerMe}>
                  <Switch
                    color="warning"
                    checked={checkElement.noQuantity}
                    onChange={(e) =>
                      onInputChange(index, "noQuantity", e.target.checked)
                    }
                  />
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className={tableStyle.buttonsContainer}>
          <Link className="btn btn-danger mx-1" to={`/store/${storeId}/pdf-checks`}>
            Откажи
          </Link>
          <span className="btn btn-success mx-1" onClick={() => onImport()}>
            Въведи
          </span>
        </div>
    </div>
  );
};

async function getCheckData(checkNumber, storeId) {
  const token = getAuthToken();
  
  const response = await fetch(
    Config.apiServerAddress +
      `/pdfchecks/single?checkId=${checkNumber}&storeId=${storeId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error("Could not fetch check data.");
  }
}

async function importCheckData(checkData, storeId) {
  const token = getAuthToken();
  console.log(`importCheckData___call_${random4digit()}`);

  const response = await fetch(
    Config.apiServerAddress + `/pdfchecks/import?storeId=${storeId}`,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      body: JSON.stringify(checkData),
    }
  );

  if (response.ok) {
    return true;
  } else {
    throw new Error("Could not import check data.");
  }
}

function random4digit() {
  return Math.floor(1000 + Math.random() * 9000);
}
