import { redirect } from "react-router";
import { getAuthToken } from "utils/auth";

export async function action({ request }) {
    const token = getAuthToken();
    let resData;
  
    const data = await request.formData().then((data) => {
      return data;
    });
    const formData = {
      planogramId: data.get("planogramId"),
      // planogramName: data.get("planogramName"),
      storeId: data.get("storeId"),
    };

    console.log(formData);
  
    if (formData.categoryId !== null || formData.categoryId !== 0) {
      return redirect(`/store/${formData.storeId}/add-product/planogram/${formData.planogramId}`);
    }
  }