import BackButton from "./BackButton";
import style from "./style/TopPageNavigation.module.css";

export function TopPageNav(props) {
  const styles = "nav ms-0 mt-0 " + style.ulSet;
  return (
    <div className={style.container}>
    <ul className={styles}>
      <li className={style.liSet}>
        <BackButton to={props.to}/>
      </li>
      {props.children}
    </ul>
    </div>
  );
}
export function TopPageNavItem(props) {
  return (
      <li className={style.liSet}>{props.children}</li>
  );
}
