import { Alert, AlertTitle } from "@mui/material";

export default function PdfCheckImportedAlert (){
    return (
      <Alert variant="standard" severity="success">
        <AlertTitle>
          <b>Въведена!</b>
        </AlertTitle>
        <p>Проверката с този номер вече е въведена в системата!</p>
      </Alert>
    );
  };