import { Alert, AlertTitle } from "@mui/material";
import { TopPageNav, TopPageNavItem } from "components/TopPageNavigation";
import PageContent from "layout/PageContent";
import { Link, redirect, useNavigate, useParams } from "react-router-dom";

import formStyle from "components/style/Form.module.css";

import PageSubtitle from "components/PageSubTitle";
import { dateEasyRead } from "utils/DateStyler";
import PendingChecksTable from "./components/PendingChecksTable";
import { useEffect, useState } from "react";
import Loading from "components/Loading";
import PdfCheckNotFoundAlert from "./components/PdfCheckNotFoundAlert";
import Config from "utils/Config";
import { getAuthToken } from "utils/auth";
import SnackBarAlert from "components/SnackBarAlert";
import DataLoadingModal from "components/DataLoadingModal";
import DataLoading from "components/DataLoading";
import { PropagateLoader } from "react-spinners";
import PropagateLoading from "components/PropagateLoading";
import PdfCheckImportedAlert from "./components/PdfCheckImportedAlert";

export default function PdfChecks() {
  const { storeId } = useParams();
  const [pendingChecks, setPendingChecks] = useState([]);
  const [loading, setLoading] = useState(true);
  const [open, setOpen] = useState(false);
  const [alert, setAlert] = useState({ severity: "", message: "" });

  var pdfCheckDeleted = localStorage.getItem("pdfCheckDeleted");
  var pdfCheckImported = localStorage.getItem("pdfCheckImported");

  // useEffect(() => {
  //   getPendingChecks(storeId).then((checks) => {
  //     setPendingChecks(checks);
  //     setLoading(false);
  //   });
  // }, [storeId]);

  useEffect(() => {
    if (pdfCheckDeleted === "true") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Успешно изтрихте PDF проверка!",
      });
    } else if (pdfCheckDeleted === "false") {
      setOpen(true);
      setAlert({
        severity: "error",
        message: "Неуспешно изтриване на PDF проверка!",
      });
    }

    if (pdfCheckImported === "true") {
      setOpen(true);
      setAlert({
        severity: "success",
        message: "Успешно въведохте данните от PDF проверката!",
      });
    } else if (pdfCheckImported === "false") {
      setOpen(true);
      setAlert({
        severity: "error",
        message: "Неуспешно въвеждане на PDF проверка!",
      });
    }

    setTimeout(() => {
      handleAlertClose();
    }, 3000);

    getPendingChecks(storeId).then((checks) => {
      if (checks.length === 0) {
        setPendingChecks(null);
      } else {
        setPendingChecks(checks);
      }

      setLoading(false);
    });

    localStorage.removeItem("pdfCheckDeleted");
    localStorage.removeItem("pdfCheckImported");
  }, [pdfCheckDeleted]);

  function handleAlertClose() {
    setOpen(false);
    setAlert({ severity: "", message: "" });
  }

  return (
    <PageContent pageTitle="PDF проверки">
      <TopPageNav>
        <TopPageNavItem>
          {/* <Link
            className="btn btn-success mx-1 btn-sm"
            to={`/store/${storeId}/pdf-checks/history`}
          >
            История
          </Link> */}
        </TopPageNavItem>
      </TopPageNav>
      {loading && <Loading />}
      {!loading && pendingChecks === null && <NoPendingChecksAlert />}
      {!loading && pendingChecks && (
        <>
          <CheckNumberForm storeId={storeId} />
          <SnackBarAlert open={open} alert={alert} />
          <PageSubtitle>Проверки очакващи въвеждане</PageSubtitle>
          <PendingChecksTable pendingChecks={pendingChecks} />
        </>
      )}
    </PageContent>
  );
}

const PDFCheckInfo = () => {
  return (
    <Alert variant="standard" severity="info">
      <AlertTitle>
        <b>Информация</b>
      </AlertTitle>
      <p>
        Въведете номера от горния десен ъгъл на PDF проверката за да започнете
        въвеждането или изберете съответната от списъка по-долу!
      </p>
    </Alert>
  );
};

const CheckNumberForm = ({ storeId }) => {
  const navigate = useNavigate();
  const [showPdfCheckNotFoundAlert, setShowPdfCheckNotFoundAlert] =
    useState(false);
  const [showPdfCheckImportedAlert, setShowPdfCheckImportedAlert] =
    useState(false);
  const [loading, setLoading] = useState(false);

  function submitDataHandler(event) {
    event.preventDefault();
    const formData = new FormData(event.target);
    const checkNumber = formData.get("checkNumber");

    setLoading(true);

    getCheckData(checkNumber, storeId)
      .then((data) => {
        console.log(data.status);
        if (data.status === "IMPORTED") {
          setShowPdfCheckImportedAlert(true);
        } else if (data.status === "PENDING") {
          navigate(`/store/${storeId}/pdf-checks/${checkNumber}`);
        } else {
          setShowPdfCheckNotFoundAlert(true);
        }
      })
      .catch((error) => {
        setShowPdfCheckNotFoundAlert(true);
      })
      .finally(() => {
        setLoading(false);
      });

    setTimeout(() => {
      setShowPdfCheckNotFoundAlert(false);
      setShowPdfCheckImportedAlert(false);
    }, 5000);
  }

  return (
    <>
      <PDFCheckInfo />
      <form
        method="post"
        onSubmit={submitDataHandler}
        className={formStyle.formContainer}
      >
        <div className="mb-3">
          {showPdfCheckNotFoundAlert && <PdfCheckNotFoundAlert />}
          {showPdfCheckImportedAlert && <PdfCheckImportedAlert />}
          {loading && <PropagateLoading />}
        </div>
        <div>
          <label>Номер на проверката*</label>
          <input
            type="number"
            name="checkNumber"
            className="form-control mb-3"
            required
          />
        </div>
        <div>
          <button type="submit" className="btn btn-success" disabled={loading}>
            Търси
          </button>
        </div>
      </form>
    </>
  );
};

const NoPendingChecksAlert = () => {
  return (
    <Alert variant="standard" severity="success">
      <AlertTitle>
        <b>Поздравления!</b>
      </AlertTitle>
      <p>Към този момент нямате невъведени PDF проверки!</p>
    </Alert>
  );
};

async function getPendingChecks(storeId) {
  const token = getAuthToken();

  const response = await fetch(
    Config.apiServerAddress + `/pdfchecks?storeId=${storeId}`,
    {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (!response.ok) {
    throw Error("Could not fetch pdf checks.");
  } else {
    const checks = await response.json();
    return checks;
  }
}

async function getCheckData(checkNumber, storeId) {
  const token = getAuthToken();

  const response = await fetch(
    Config.apiServerAddress +
      `/pdfchecks/single?checkId=${checkNumber}&storeId=${storeId}`,
    {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
    }
  );

  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    throw new Error("Could not fetch check data.");
  }
}
